import React from 'react';
import Name from '../../components/ui/Name';
import profile from './profile';

export default {
	fields: {
		...profile.fields,
		fullName: {
			label: `Nom de la marque`,
			placeholder: `Lacoste`,
			helper: ``
		}
	},
	messages: {
		saved: `Marque enregistrée avec succès !`,
	},
	tab: profile.tab,
	tabs: profile.tabs,
	modales: {
		deleteProfile: {
			title: `Supprimer une marque`,
			body: props => (<div>Êtes-vous sûr de vouloir supprimer <span className="modale-profile-name"><Name {...props } /></span> ?<br />Attention cette action est irréversible et ne pourra être annulée.</div>),
			yes: `Supprimer`,
			no: `Annuler`
		},
		addProfile: {
			title: `Ajouter une marque`,
			cancel: `Annuler`,
			confirm: `Créer la marque`,
			matchingProfiles: `Les marques suivantes peuvent correspondre à la marque que vous souhaitez créer. Si c'est le cas, merci d'éditer la fiche de la marque correspondant et ne pas créer une nouvelle`,
			cgv: `Je certifie que la marque que je veux créer n'existe pas déjà.`,
			success: `Marque créée avec succès ! Vous pouvez maintenant éditer sa fiche !`,
			search: {
				noResult: ``,
			}
		},
		confirmLightMerge: {
			title: `Lier un compte social à une marque`,
			body: profile => (<div>Êtes-vous sûr(e) de vouloir lier ce compte social à <span className="modale-profile-name"><Name {...profile } /></span> ?</div>),
			yes: `Oui, lier`,
			no: `Annuler`
		},
		merge: {
			title: `Fusionner avec...`,
			cancel: `Annuler`,
			matchingProfiles: `Sélectionnez la marque avec laquelle vous souhaitez fusionner.`,
			merge: `Fusionner`,
			search: {
				noResult: ``,
			}
		},
		confirmMerge: {
			title: `Sélectionner les champs à conserver`,
			confirm: `Fusionner`,
			cancel: `Annuler`,
			emptyProfile: `Champs libres`
		},
		mergeError: {
			title: 'Fusion impossible',
			body: `Désolé, ces 2 marques correspondent à des célébrités distinctes, ou des membres community, et ne peuvent donc pas être fusionnées.`,
			ok: `Ok`,
		},
	}
}
