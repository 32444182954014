const getDomain = () => {
	if (window.location.hostname.indexOf('brandandcelebrities.com') > -1) return "brandandcelebrities.com";

	return "kolsquare.com";
}
const domain = getDomain();


const DEV = {
	api: 'http://api.localdev.com:3000',
	cookieToken: '_kolsquare_api_token_development',
	urls: {
		app: 'http://admin.localdev.com:3003',
		genesisProfile: id => `http://app.localdev.com:3005/admin/celebrities/${id}`,
		createGenesisProfile: id => `http://app.localdev.com:3005/admin/celebrities/new?profile_id=${id}`,
		signup: `http://kolsquare.localdev.com:3004/register?redirect_to=${window.location.href}`,
		influenceProfile: `http://influence.localdev.com:3001/profiles/`,
		genesis: `http://app.localdev.com:3005`,
		community: `http://community.localdev.com:3002`,
		influence: `http://influence.localdev.com:3001`,
	},
}

const getRemoteConfig = () => {
	const { hostname, protocol } = window.location;
	return {
	  api: `${protocol}//${hostname}:3000`,
	cookieToken: '_kolsquare_api_token_development',
	  urls: {
		app: `${protocol}//${hostname}:3003`,
		genesisProfile: id => `${protocol}//${hostname}:3005/admin/celebrities/${id}`,
		createGenesisProfile: id => `${protocol}//${hostname}:3005/admin/celebrities/new?profile_id=${id}`,
		signup: `${protocol}//${hostname}:3004/register?redirect_to=${window.location.href}`,
		influenceProfile: `${protocol}//${hostname}:3001/profiles/`,
		genesis: `${protocol}//${hostname}:3005`,
		community: `${protocol}//${hostname}:3002`,
		influence: `${protocol}//${hostname}:3001`,
	  },
	};
  };

const STAGING = {
	api: 'https://api-staging.kolsquare.com',
	cookieToken: '_kolsquare_api_token_staging',
	urls: {
		app: `https://admin-staging.${domain}`,
		genesisProfile: id => `https://app-staging.brandandcelebrities.com/admin/celebrities/${id}`,
		createGenesisProfile: id => `https://app-staging.brandandcelebrities.com/admin/celebrities/new?profile_id=${id}`,
		signup: `https://staging.kolsquare.com/register?redirect_to=${window.location.href}`,
		influenceProfile: `https://influence-staging.kolsquare.com/profiles/`,
		genesis: `https://app-staging.brandandcelebrities.com/`,
		community: `https://community-staging.kolsquare.com`,
		influence: `https://influence-staging.kolsquare.com`,
	},
}

const LAB = {
	api: 'https://api-lab.kolsquare.com',
	cookieToken: '_kolsquare_api_token_lab',
	urls: {
		app: `https://admin-lab.${domain}`,
		genesisProfile: id => `https://app-lab.brandandcelebrities.com/admin/celebrities/${id}`,
		createGenesisProfile: id => `https://app-lab.brandandcelebrities.com/admin/celebrities/new?profile_id=${id}`,
    signup: `https://lab.kolsquare.com/register?redirect_to=${window.location.href}`,
		influenceProfile: `https://influence-lab.kolsquare.com/profiles/`,
		genesis: `https://app-lab.brandandcelebrities.com/`,
		community: `https://community-lab.kolsquare.com`,
		influence: `https://influence-lab.kolsquare.com`,
	},
}


const PROD = {
	api: 'https://api.kolsquare.com',
	cookieToken: '_kolsquare_api_token',
	urls: {
		app: `https://admin.${domain}`,
		genesisProfile: id => `https://app.brandandcelebrities.com/admin/celebrities/${id}`,
		createGenesisProfile: id => `https://app.brandandcelebrities.com/admin/celebrities/new?profile_id=${id}`,
		signup: `https://www.kolsquare.com/register?redirect_to=${window.location.href}`,
		influenceProfile: `https://influence.kolsquare.com/profiles/`,
		genesis: `https://app.brandandcelebrities.com/`,
		community: `https://community.kolsquare.com`,
		influence: `https://influence.kolsquare.com`,
	},
}

export const ENV_DEV = "env---dev";
export const ENV_REMOTE = "env---remote";
export const ENV_STAGING = "env---staging";
export const ENV_LAB = "env---lab";
export const ENV_PROD = "env---prod";

const location = window.location.hostname;

// REACT_APP_ENV=staging npm start
const overrideEnv = process.env.REACT_APP_ENV;

export const env = (() => {
	// If ENV is given, use it
	if(overrideEnv) {
	  switch (overrideEnv) {
		case 'prod':
		  return ENV_PROD;
		case 'staging':
		  return ENV_STAGING;
		case 'lab':
		  return ENV_LAB;
		default:
		  throw new Error(`Incorrect ENV override: ${overrideEnv}`);
	  }
	}

	// If no ENV given, infer from URL
	if (location.indexOf('dev-kolsquare-app-') !== -1) return ENV_REMOTE;
	if (
		location.indexOf('localhost') !== -1 ||
		location.indexOf('localdev') !== -1 ||
		location.indexOf('192.168.') !== -1
	)
		return ENV_DEV;
	if (location.indexOf('staging.kolsquare.com') !== -1) return ENV_STAGING;
	if (location.indexOf('lab.kolsquare.com') !== -1) return ENV_LAB;
	return ENV_PROD;
})();

export const DEV_DEBUG = !overrideEnv && location.includes('localhost');
export const IS_LOCAL_DEV = location.includes('localhost') || location.includes('localdev');

export const conf = {
  ...(env === ENV_REMOTE
    ? getRemoteConfig()
    : env === ENV_DEV
    ? DEV
    : env === ENV_STAGING
    ? STAGING
    : env === ENV_LAB
    ? LAB
    : PROD),
  GATracker: process.env.REACT_APP_GA_TRACKER,
  appsignal: process.env.REACT_APP_APPSIGNAL,
  overrideEnv,
};

export const accountsCsvExportUrl = token => `${conf.api}/catalog/contacts/customers_export?token=${token}`;

export const salesforceCompanyUrl = 'https://kolsquare.lightning.force.com/lightning/r/Account/{{sfId}}/view';
