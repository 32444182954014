import React from "react";
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';

import ConfirmChangePage from '../components/ui/modales/ConfirmChangePage';

import { env, ENV_PROD } from './env';
import reducers from '../reducers/index';
import getLexique from '../locales';
import { openModal, closeModal } from '../actions/env';

export const history = createBrowserHistory({
	getUserConfirmation: (message, callback) => {
		const dispatch = getDispatcher();
		const lexique = getLexique(getStore().env.locale);

		// show confirmation modale
		dispatch(openModal({
			content: <ConfirmChangePage
						lexique={lexique[message].modales.confirmChangePage}
						onDiscard={() => {
							dispatch(closeModal());
							callback(false);
						}}
						onConfirm={() => {
							dispatch(closeModal());
							callback(true);
						}}
					 />,
			closable: true,
			onClose: () => {
				dispatch(closeModal());
				callback(false);
			}
		}));
	}
});

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunkMiddleware),
        (window.__REDUX_DEVTOOLS_EXTENSION__ && env !== ENV_PROD) ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

if (module.hot) {
	// Enable Webpack hot module replacement for reducers
	module.hot.accept('../reducers', () => {
		const nextRootReducer = require('../reducers/index');
		store.replaceReducer(nextRootReducer);
	});
}

export default store;

export const getStore = () => store.getState();
export const getDispatcher = () => store.dispatch;
