import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import { FILTERS } from '../config/discovery';
import * as DISCOVERY from '../constants/discovery';
import * as PROFILE from '../constants/profile';

const defaultFilters = Object.keys(FILTERS).reduce((p, c) => Object.assign({}, p, { [c]: FILTERS[c].default}), {flags:[], partition: ''});

const initialState = {
	search: {
		loading: false,
		filters: defaultFilters,
		results: [],
		totalResults: 0,
	}
};

const reducers = {
	[DISCOVERY.SEARCH_REQUEST]: state => update(state, {
		search: {
			loading: { $set: true }
		}
	} ),
	[DISCOVERY.SEARCH_COMPLETE]: (state, {payload:{profiles:{results, total}, excludeId}}) => update(state, {
		search: {
			loading: { $set: false },
			results: { $set: excludeId ? results.filter(res => res.id !== excludeId) : results },
			totalResults: { $set: total }
		}
	} ),
	[DISCOVERY.FILTER_UPDATE]: (state, {payload:{value, filter}}) => update(state, {
		search: {
			filters: {
				[filter]: { $set: value },
			}
		}
	} ),
	[PROFILE.SAVE_COMPLETE]: (state, {payload:{profile:{id}}}) => update(state, {
		search: {
			results: { $set: state.search.results.filter(p => p.id !== id) }
		}
	} ),
}


export default createReducer(initialState, reducers);
