export const TABS = [
	{
		key: 'default',
		fields: ['fullName', 'what', 'socialNetworks'],
		required: ['fullName']
	},
	{
		key: 'influence',
		fields: ['fullName', 'what', 'socialNetworks'],
		required: ['fullName']
	},
];


export const ALL = ['fullName', 'what', 'socialNetworks'];


export const VALUES = ["community", "entertainment", "place", "porn", "society", "sport", "animals", "other"];
