import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import _ from 'lodash';

import { defaultFields } from 'config/global';
import * as PROFILE from 'constants/profile';
import { STATUS_QUALIFIED } from 'config/profile';

const initialState = {
	loading: false,
	details: null,
	edition: null,
};

const reducers = {
	[PROFILE.LOAD_REQUEST]: state => update(state, {
		loading: { $set: true },
	}),
	[PROFILE.LOAD_COMPLETE]: (state, { payload: { profile } }) => update(state, {
		loading: { $set: false },
		details: { $set: _.merge({}, defaultFields, profile) },
		edition: { $set: _.merge({}, defaultFields, _.cloneDeep(profile)) }
	}),
	[PROFILE.CHANGES_DISCARD]: state => update(state, {
		edition: { $set: _.cloneDeep(state.details) },
	}),
	[PROFILE.FIELD_UPDATE]: (state, { payload: { value, field } }) => update(state, {
		edition: {
			[field]: { $set: value }
		}
	}),
	[PROFILE.FIELD_UPDATE_BOTH]: (state, { payload: { value, field } }) => update(state, {
		edition: {
			[field]: { $set: value }
		},
		details: {
			[field]: { $set: value }
		}
	}),
	[PROFILE.SAVE_COMPLETE]: (state, { payload: { profile } }) => update(state, {
		details: { $set: profile },
		edition: { $set: profile },
	}),
	[PROFILE.SNA_CERTIFY]: (state, { payload: { snaId } }) => update(state, {
		edition: {
			socialNetworks: {
				$set: state.edition.socialNetworks.map(sna => sna.uid === snaId ? ({ ...sna, isCertified: true }) : ({ ...sna }))
			}
		},
	}),
	[PROFILE.SNA_TOGGLE_COLLECT]: (state, { payload: { snaId } }) => update(state, {
		edition: {
			socialNetworks: {
				$set: state.edition.socialNetworks.map(sna => sna.uid === snaId ? ({ ...sna, isCollected: !sna.isCollected }) : ({ ...sna }))
			}
		},
		details: {
			socialNetworks: {
				$set: state.details.socialNetworks.map(sna => sna.uid === snaId ? ({ ...sna, isCollected: !sna.isCollected }) : ({ ...sna }))
			}
		},
	}),
	[PROFILE.SNAS_CERTIFY_ALL]: state => update(state, {
		edition: {
			socialNetworks: {
				$set: state.edition.socialNetworks.map(sna => ({ ...sna, isCertified: true }))
			}
		},
		details: {
			socialNetworks: {
				$set: state.details.socialNetworks.map(sna => ({ ...sna, isCertified: true }))
			}
		},
	}),
	[PROFILE.SNAS_SUGGESTIONS]: (state, { payload: suggestions }) =>
	update(state, {
		edition: {
		  profilesSuggestions: {
			$set: suggestions
			  .filter((sna) => sna.profile?.status === STATUS_QUALIFIED)
			  .map((sna) => {
				const { profile, ...rest } = sna;
				return { ...profile, socialNetworks: [{ ...rest }] };
			  }),
		  },
		  socialNetworksSuggestions: {
			$set: suggestions.filter(
			  (sna) => !(sna.profile?.status === STATUS_QUALIFIED),
			),
		  },
		},
	  },
	),
};


export default createReducer(initialState, reducers);
