import _keyBy from 'lodash.keyby';

const mapFeature = feature => ({
    id: feature.name,
    description: feature.description,
    isActive: feature.is_active,
    membersCount: feature.memberships_count || 0,
    isAdmin: feature.application === "catalog",
    isUser: feature.application === "influence",
    locked: feature.name.endsWith('/unicore') || !feature.destroyable,
    limitCountKey: feature.limit_count_key || '',
    limitDescription: feature.limit_description || '',
})

const mapPackage = pack => ({
    id: pack.name,
    description: pack.description,
    isActive: pack.is_active,
    isAdmin: pack.application === "catalog",
    isUser: pack.application === "influence",
    features: pack.features,
    locked: pack.name === "package/standard" || pack.memberships_count > 0 || pack.name === 'admin/semideus',
    membersCount: pack.memberships_count || 0,
})

const getFeatures = {
    fromApi: features => _keyBy(features.map(mapFeature), 'id'),
}


const getPackages = {
    fromApi: packages => _keyBy(packages.map(mapPackage), 'id')
}

const mapUser = ({ id, full_name, contact_id, admin_id, company_name, created_at }) => ({
  id,
  fullName: full_name || '',
  contactId: contact_id,
  adminId: admin_id,
  companyName: company_name || '',
  createdAt: created_at,
});

const getFeatureOrPackageUsers = {
  fromApi: ({ total, rows }) => ({
    total,
    rows: rows.map(mapUser),
  }),
};

export default {
    getFeatures,
    getPackages,
    getFeatureOrPackageUsers,
};