import { Activities } from '@brandandcelebrities/activities-themes';
import activitiesList from '../locales/fr-FR/activities';
import countries from '../locales/fr-FR/countries';
import themes from '../locales/fr-FR/themes';
import { STATUS_QUALIFIED, STATUS_REJECTED } from './profile';
import { VALUES } from './other';

export const NETWORKS = ["facebook", "instagram", "youtube", "twitter", "tiktok"];

/* Definition of available fields, and how they should be displayed */
const pinnedCountries = ['FR', 'US', 'GB', 'BE', 'ES', 'IT'];

const activities = new Activities(activitiesList);

export const LOCALES = ["fr", "en"];

export const FIELDS = {
	visible: {
		type: "toggle",
		default: false,
	},
	enlisted: {
		type: "toggle",
		default: false,
	},
	fullName: {
		type: "text",
		default: "",
	},
	firstName: {
		type: "text",
		default: "",
	},
	lastName: {
		type: "text",
		default: "",
	},
	name: {
		type: "text",
		default: "",
	},
	stageName: {
		type: "text",
		default: "",
	},
	members: {
		type: "tags",
		params: {
		},
		default: [],
	},
	gender: {
		type: "radio",
		default: "",
		datas: ['male', 'female', 'other'],
	},
	nationalities: {
		type: "select-multiple",
		params: {
			max: 2,
			isCountries: true,
		},
		default: [],
		datas: Object.keys(countries),
		pinned: pinnedCountries,
	},
	phone: {
		type: "text",
		default: ""
	},
	email: {
		type: "text",
		default: "",
		params: {
			disabled: true,
		}
	},
	otherEmails: {
		type: "tags",
		params: {
			max: 5,
		},
		default: [],
	},
	activities: {
		type: "select-multiple",
		default: [],
		datas: activities.excludeStage(0).keys(),
		params: {
			isActivity: true,
			max: 3,
			limit: 215
		}
	},
	shortDescription: {
		type: "text-multi",
		params: {
			max: 80,
			multiline: false,
		},
		default: [],
	},
	description: {
		type: 'textarea-multi',
		params: {
			max: 3000,
			multiline: true,
			rows: 8,
		},
		default: [],
	},
	longDescription: {
		type: 'textarea-multi',
		default: [],
		params: {
			multiline: true,
			rows: 15,
		}
	},
	birthdate: {
		type: 'date',
		default: {
			year: '',
			month: '',
			day: ''
		}
	},
	keyFacts: {
		type: "tags-multi",
		params: {
			max: 3,
		},
		default: [],
	},
	website: {
		type: "url",
		default: "",
	},
	socialNetworks: {
		type: 'social-networks',
		default: [],
	},
	socialNetworksSuggestions: {
		type: 'social-networks',
		default: [],
		params: {
			ref: 'socialNetworks',
			addIcon: true,
			addMore: false,
			forceHideGavel: true,
			showUrlInsteadOfUsername: true,
		}
	},
	profilesSuggestions: {
		type: 'profiles',
		default: [],
		params: {
			asButton: true,
			buttonProfile: true,
			buttonLink: true,
			hideDiscoveryStatuses: true,
		}
	},
	influencingCountries: {
		type: "select-multiple",
		params: {
			max: 3,
			isCountries: true,
		},
		default: [],
		datas: Object.keys(countries),
		pinned: pinnedCountries,
	},
	influencingCountriesAuto: {
		type: "chips",
		params: {
			isCountries: true,
		},
		default: [],
		datas: Object.keys(countries),
	},
	themesInfluence: {
		type: "select-multiple",
		params: {
			max: 5,
			isCountries: false,
			limit: 30
		},
		default: [],
		datas: Object.keys(themes)
	},
	addresses: {
		type: 'multiple-combo-select-text',
		default: [
			{ key: `living`, placeSearched: ''},
			{ key: `socialData`, placeSearched: '', disabled: true }
		],
		keys: ['living', 'socialData'],
	},
	status: {
		type: 'select',
		default: STATUS_QUALIFIED,
		datas: [STATUS_QUALIFIED, STATUS_REJECTED],
		params: {
			searchable: false,
			sort: false,
		}
	},
	what: {
		type: 'select',
		default: '',
		datas: VALUES,
		params: {
			searchable: false,
			sort: false,
		}
	}
}

export const defaultFields = Object.keys(FIELDS).reduce((p, c) => Object.assign({}, p, { [c]: FIELDS[c].default}), {});

export const entitiesTypes = [
  "profile",
  "collective",
  "brand",
  "media",
  "other",
];
