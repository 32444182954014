import _ from 'lodash';
import { QUALIF_STATUS } from 'constants/insideSales';
import { VALUES } from 'config/other';
import { NETWORKS } from 'config/global';
import { STATUS } from 'config/profile';
import moment from 'moment';

const imgskeys = ['nature', 'water', 'sport', 'tennis', 'golf', 'game', 'mountain', 'sand', 'beach', 'ice', 'glacier', 'people', 'soccer', 'baseball'];
const networks = NETWORKS;
const engines = ['influence', 'event', 'ambassador'];
const countryStatsKeys = ['FR', 'US', 'BD', 'BE', 'BM', 'BN', 'GT', 'GS', 'GR', 'RU', 'GB', 'IT', 'ES', 'DE', 'BR', 'LU', 'FI', 'NO', 'DK', 'CN', 'AE', 'SE', 'CH', 'PL', 'MA', 'MX', 'AR', 'RU', 'PT', 'NL'];

export const randomNumber = (min, max) => _.random(min, max);
export const randomFromArray = arr => arr[_.random(0, arr.length - 1)];
export const randomBool = () => Math.floor(Math.random() * 2) === 1;

export const getRandomAccesses = () => engines.map(e => randomBool() ? e : null).filter(f => f !== null);

export const randomFirstName = () => randomFromArray(["John", "Paul", "Henri", "Jack", "Dimitri", "Ambers", "Zack", "Carla", "Pierre", "André", "Amine", "David", "Karim", "Mike", "Lova", "Sandwell", "Michel", "Ferdinand", "Théophile", "Antonio", "Chang"]);
export const randomLastName = () => randomFromArray(["Durand", "Dupont", "Parker", "Spencer", "Rossi", "Rossini", "Moura", "Pierrot", "Jan", "Chevers", "Gnol", "Cargol", "Zableola", "Fooo", "Barr", "Ululu"]);

export const randomCompanyName = () =>
  randomFromArray([
    "Leroy Merlin",
    "Assu 2000",
    "Nike",
    "Adidas",
    "Puma",
    "Cos",
    "Carhartt",
    "Casio",
    "The North Face",
  ]);

export const getRandomStatus = () => randomFromArray(["qualified", "unqualified", "rejected", "blocked", "closed"]);
export const getRandomDate = (format) => {
	const start = new Date(2018, 0, 1);
	const end = new Date();
	const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
	return moment(date).format(format);
};
export const getDeletionDate = (status) => status === "closed" ? getRandomDate() : null;

export const generateSearchBrands = () => {
	const id = _.random(0, 50000000);
	return ({
		id: id,
		full_name: `Brand and Celebrities ${id}`,
		pseudo: `BR ${id}`,
		picture_url: getRandomPicture(),
	});
};

export const generateSearchProfile = (searchValue = "") => {
  const id = _.random(0, 50000000);
  const firstName = randomFirstName();
  const lastName = randomLastName();
  const nbSnas = randomNumber(1, 4);
  return {
    id: id,
    first_name: firstName,
    last_name: `${lastName} ${id}`,
    pseudo: `EP ${id}`,
	picture_url: getRandomPicture(),
	status: randomFromArray(STATUS),
    usernames: [
      `${firstName}.${lastName}`,
      ...(searchValue && randomBool() && randomBool() ? [searchValue] : []),
      ...Array(nbSnas - 1)
        .fill()
        .map(
          () =>
            `${firstName} ${randomFromArray([
              firstName,
              lastName,
              searchValue,
            ])}${randomNumber(100000, 500000)}`
        ),
    ],
    uids: Array(nbSnas)
      .fill()
      .map(
        () =>
          `${nbSnas === 1 || randomBool() ? searchValue : ""}${randomNumber(
            100000,
            500000
          )}`
      ),
    is_visible_on_influence: randomBool(),
  };
};

export const generateSearchSna = () => {
	const id = _.random(0, 50000000);
	return ({
		id: id,
		full_name: `TBQ ${id}`,
		pseudo: '',
		status: "unqualified",
	});
};

export const getRandomProfile = ({ id, type }) => {
	const status = getRandomStatus();
	const closed_at = getDeletionDate(status);
	const randomProfile =  {
		id: id,
		first_name: randomFirstName(),
		last_name: `${randomLastName()} ${id}`,
		//full_name: type === 'brands' ? `Brand ${id}` : `Enjoy Phoenix ${id}`,
		pseudo: type === 'brands' ? `BR  ${id}` : `EP ${id}`,
		activities: [],
		status,
		closed_at,
		registered: randomBool(),
		has_community_access: randomBool(),
		registered_at: getRandomDate(),
		enlisted: false,
		type: randomFromArray(["brand", "profile"]), //type === 'brands' ? 'brand' : 'profile',
		category: randomFromArray(VALUES),
		email: `testmy${randomFromArray(networks)}@test.com`,
		influence_countries_auto: ['FR', 'DE'],
		phone: `06891010${random(0, 500)}`,
		snas: [generateRandomSNA(id)],
		picture_url: getRandomPicture(),
		descriptions: [
			{
				locale: 'fr',
				full_descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt eo quo voluptas nulla pariatur?\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt eo quo voluptas nulla pariatur?\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
			}
		],
	};
	randomProfile.has_community_access && Object.assign(randomProfile, { subscribed_to_stats_report: randomBool() });
	return randomProfile;
};

export const generateRandomSNA = id => {
  const isSpammer = randomBool();
  return {
    label: randomFromArray(networks),
    username: `SNA Username ${id}`,
    uid: random(0, 50000),
    url: 'http://instagram.com/tsongaofficiel/',
    profile_picture: getRandomPicture(),
    is_a_spammer: isSpammer,
    is_whitelisted: isSpammer ? randomBool() : true,
    visible: true,
  }
};

export const generateSearchQualification = () => {
	const id = _.random(0, 50000000);
	const day = _.random(10, 30);
	const randomize = (x) => x[Math.floor(Math.random() * x.length)];
	const adminUserId = randomize(Array(3).fill().map((v, i) => i + 1));
	const companyName = randomize(['Leroy Merlin', 'Assu 2000', 'Nike', 'Adidas', 'Puma', 'Cos', 'Carhartt', 'Casio', 'The North Face']);
	const status = randomize(QUALIF_STATUS);

	return ({
		id: id,
		created_at: `2018-04-${day}T08:34:03.000Z`,
		admin_user_id: adminUserId,
		company_name: companyName,
		status: status
	});
};

export const getRandomPicture = () => `https://loremflickr.com/g/640/640/${randomFromArray(imgskeys)}`;
export const getRandomNetwork = () => randomFromArray(networks);

export const random = (min, max) => _.random(min, max);

// LOGS
export const generateLogs = () => ({
	total: _.random(0, 500),
	logs: Array(20).fill().map(() => ({
		id: _.random(0, 50000000),
		name: randomFromArray(['public_profile_to_brand', 'public_profile_to_collective', 'public_profile_to_other', 'public_profile_merged', 'public_profile_updated_and_qualified']),
		timestamp: '2010-10-02',
		admin_user_id: _.random(1, 3),
		profile_id: _.random(0, 500000),
		profile_type: 'profile',
		data: {
			from: {
				"status": "unqualified",
				"pseudo": "PageDJAbdel",
				"gender": null,
				"influence_themes": null,
				"birthdate": "1985-08-02",
			},
			to: {
				"status": "qualified",
				"pseudo": "DJ Abdel",
				"gender": "male",
				"influence_themes": ["music"],
				"activity": "dj"
			}
		},
		to_review: _.random(0, 100) <= 15,
	}))
});


export const generateLogsStats = () => ({
	"1": {
		public_profile_to_brand: _.random(0, 5000),
		public_profile_to_collective: _.random(0, 5000),
		public_profile_to_other: _.random(0, 5000),
		public_profile_merged: _.random(0, 5000),
		public_profile_updated_and_qualified: _.random(0, 5000),
	},
	"2": {
		public_profile_to_brand: _.random(0, 5000),
		public_profile_to_collective: _.random(0, 5000),
		public_profile_to_other: _.random(0, 5000),
		public_profile_merged: _.random(0, 5000),
		public_profile_updated_and_qualified: _.random(0, 5000),
	},
	"3": {
		public_profile_to_brand: _.random(0, 5000),
		public_profile_to_collective: _.random(0, 5000),
		public_profile_to_other: _.random(0, 5000),
		public_profile_merged: _.random(0, 5000),
		public_profile_updated_and_qualified: _.random(0, 5000),
	}
});


export const generateSearchAccount = id => {
	const isTeam = randomBool();
	const isCompany = !isTeam && randomBool();
	const isAdmin = !isTeam && !isCompany && randomBool();
	return Object.assign({
		id: id || _.random(10000, 5000000),
	},
		isTeam ? {
			name: `My awesome team`,
			type: 'team',
			length: _.random(1, 20)
		} : null,
		isCompany ? {
			name: `My company`,
			type: 'salesforce_account',
		} : null,
		!isTeam && !isCompany && isAdmin ? {
			first_name: randomFirstName(),
			last_name: randomLastName(),
			type: 'admin_user',
		} : null,
		!isTeam && !isCompany && !isAdmin ? {
			first_name: randomFirstName(),
			last_name: randomLastName(),
			type: "contact",
			has_team: randomBool(),
			team_name: `B&C`,
		} : null);
};

export const generateRandomUser = () => ({
  id: _.random(10000, 5000000),
  first_name: randomFirstName(),
  last_name: randomLastName(),
  company_name: "ACME",
  position_title: "Peon",
  ag_category_id: 4,
  phone: "0987654321",
  salesforce_account: {
    id: randomNumber(0, 50000000),
    name: "ACME",
    account_owner_first_name: randomFirstName(),
    account_owner_last_name: randomLastName(),
  },
  country: "FR",
  activation_email_sent: randomBool(),
  is_confirmed: randomBool(),
  is_active: randomBool(),
  deactivated: randomBool(),
  email: "john@acme.com",
  locale: "fr-FR",
  //package_id: 99,
});

export const generateRandomTeam = id => ({
	id: id || _.random(10000, 5000000),
	name: 'The great time!',
	rights: [],
	members: Array(_.random(2, 20)).fill().map(generateRandomUser),
});

export const generateRandomStats = () => countryStatsKeys.map(country => ({
	key: country,
	data: [...Array(6)].map(() => ({
		key: randomFromArray(['*-4999.0', '"5000.0-9999.0', '10000.0-9999.0']),
		to: randomFromArray([4999, 9999, 49999, 99999]),
		doc_count: _.random(10, 50000),
		qualified: {
			"doc_count": _.random(10, 500)
		}
	}))
}));

const generateRandomProduct = () => ({
  start_date: getRandomDate(),
  end_date: getRandomDate(),
  licences_count: randomNumber(1, 3),
  days: randomFromArray([null, 100]),
  name: `Abonnement Kolsquare`,
});

const generateRandomContact = (paid_package) => () => ({
  id: randomNumber(0, 50000000),
  first_name: randomFirstName(),
  last_name: randomLastName(),
  package_description: `Package ${
    !paid_package ? "gratuit" : randomBool() ? "payant" : "search"
  }`,
  monitorings: [],
  is_active: randomBool(),
  deactivated: randomBool(),
  team_id: randomNumber(0, 50000000),
  team_name: `Team ${randomFromArray(countryStatsKeys)}`,
});
export const generateRandomCompany = () => ({
  id: randomNumber(0, 50000000),
  name: randomCompanyName(),
  country: randomFromArray(["France", "Italie", "Japon", "Corée"]),
  account_owner_first_name: randomFromArray(["Alix", "Maëva"]),
  account_owner_last_name: randomFromArray(["Dumarest", "Picault"]),
  sf_id: "0011t00000CpdVeAAJ",
  active_licence_groups: Array(randomNumber(0, 3)).fill().map(generateRandomProduct),
  inactive_licence_groups: Array(randomNumber(0, 2))
    .fill()
    .map(generateRandomProduct),
  paying_contacts: Array(randomNumber(0, 7))
    .fill()
    .map(generateRandomContact(true)),
  free_contacts: Array(randomNumber(0, 3))
    .fill()
    .map(generateRandomContact(false)),
});
export const generateRandomCompanyLicences = () => [
  {
    // not moderate, previous year, inactive
    id: 1,
    licence_group_id: 1,
    name: "1.0 Software Licence Enterprise",
    days: null,
    used_days: null,
    contact_id: 12,
    start_date: "2020-01-01",
    end_date: "2020-12-31",
    is_active: false,
  },
  {
    // not moderate, current year
    id: 2,
    licence_group_id: 2,
    name: "1.0 Software Licence Enterprise",
    days: null,
    used_days: null,
    contact_id: null,
    start_date: "2021-01-01",
    end_date: "2021-12-31",
    is_active: true,
  },
  {
    // not moderate, up to next year
    id: 4,
    licence_group_id: 4,
    name: "1.0 Software Licence Enterprise",
    days: null,
    used_days: null,
    contact_id: null,
    start_date: "2021-06-01",
    end_date: "2022-06-30",
    is_active: true,
  },
  {
    // moderate, not entamed, not assigned
    id: 3,
    licence_group_id: 3,
    name: "1.0.1 Software Licence Enterprise (Moderate)",
    days: 100,
    used_days: 0,
    contact_id: null,
    start_date: "2021-01-01",
    end_date: "2021-12-31",
    is_active: true,
  },
  {
    // another moderate, not entamed, assigned
    id: 5,
    licence_group_id: 3,
    name: "1.0.1 Software Licence Enterprise (Moderate)",
    days: 100,
    used_days: 0,
    contact_id: 12,
    start_date: "2021-01-01",
    end_date: "2021-12-31",
    is_active: true,
  },
  {
    // another moderate, entamed, assigned
    id: 6,
    licence_group_id: 3,
    name: "1.0.1 Software Licence Enterprise (Moderate)",
    days: 100,
    used_days: 5,
    contact_id: 13,
    start_date: "2021-01-01",
    end_date: "2021-12-31",
    is_active: true,
  },
  {
    // another moderate, entamed, then unassigned
    id: 7,
    licence_group_id: 3,
    name: "1.0.1 Software Licence Enterprise (Moderate)",
    days: 100,
    used_days: 7,
    contact_id: null,
    start_date: "2021-01-01",
    end_date: "2021-12-31",
    is_active: true,
  },
];