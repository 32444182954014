import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default ({error, errorInfo}) => {
	console.error("ERROR:",  error, errorInfo);  //eslint-disable-line no-console
	return (
		<Dialog
			open={true}
			onClose={null}
			disableBackdropClick
			disableEscapeKeyDown
		>
			<DialogTitle>
				Oups...
			</DialogTitle>
			<DialogContent>
				<Typography variant="body2" component="p" paragraph>
					Nous sommes désolé, il semblerait que l'application ait crashé.<br />
					Veuillez recharger la page pour continuer à utiliser l'application.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => window.location.reload()} color="primary">
					Recharger
				</Button>
			</DialogActions>
		</Dialog>
	);
}
