const returnCb = (cb) => (typeof cb === 'function' ? cb() : cb);

export const doAfterTimeout = (cb, timeout) => {
  let timer;

  const promise1 = new Promise((resolve) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => resolve(returnCb(cb)), timeout);
  });

  return Promise.race([promise1])
    .then((value) => value || true)
    .finally(() => clearTimeout(timer));
};
