import global from './global';
import profile from './profile';
import collective from './collective';
import brand from './brand';
import media from './media';
import catalog from './catalog';

export default {
	title: `Discovery réseaux sociaux`,
	qualify: {
		label: `Ce compte social correspond à :`,
		profile: `Une personne`,
		collective: `Un collectif`,
		brand: `Une marque`,
		media: `Un média`,
		other: `Autre chose`,
		popup: `Pour plus de confort, acceptez l'ouverture des popups sur ce site.`
	},
	filters: {
		network: {
			label: `Compte social`,
			all: ` Tous les réseaux`,
			...global.networks
		},
		flags: {
			label: ``,
		},
		partition: {
			more: `Plus`,
			label: `Partition`,
			reset: `Toutes`,
		},
	},
	check: {
		profile: {
			addProfile: {
				...profile.modales.addProfile,
				matchingProfiles: `Les profils suivants peuvent correspondre au profil que vous souhaitez créer. Si c'est le cas, utilisez le bouton "Lier" pour associer ce compte à ce profil.`,
				search: catalog.search,
			},
			/*confirm: `Vérifier le profil`*/
			fields: profile.fields,
		},
		collective: {
			addProfile: {
				...collective.modales.addProfile,
				matchingProfiles: `Les collectifs suivants peuvent correspondre au collectif que vous souhaitez créer. Si c'est le cas, utilisez le bouton "Lier" pour associer ce compte à ce collectif.`,
				search: catalog.search,
			},
			/*confirm: `Vérifier le profil`*/
			fields: collective.fields,
		},
		brand: {
			addProfile: {
				...brand.modales.addProfile,
				matchingProfiles: `Les marques suivantes peuvent correspondre à la marque que vous souhaitez créer. Si c'est le cas, utilisez le bouton "Lier" pour associer ce compte à cette marque.`,
				search: catalog.search,
			},
			/*confirm: `Vérifier le profil`*/
			fields: brand.fields,
		},
		media: {
			addProfile: {
				...media.modales.addProfile,
				matchingProfiles: `Les médias suivants peuvent correspondre au média que vous souhaitez créer. Si c'est le cas, utilisez le bouton "Lier" pour associer ce compte à ce média.`,
				search: catalog.search,
			},
			/*confirm: `Vérifier le profil`*/
			fields: media.fields,
		},
		other: {
			select: {
				label: `Type`,
				choose: `Choisissez une option`,
				values: {
					community: `Communauté`,
					entertainment: `Divertissement`,
					place: `Lieu`,
					porn: `Pornographie`,
					society: `Association`,
					sport: `Sport`,
					animals: `Animaux`,
					other: `Autre`
				},
			},
			confirm: `Valider`,
		}
	},
	congrats: {
		title: "Félicitations !",
		sentences: [
			`You're a superstar! May today's success be the beginning of tomorrow's achievements.`,
			`Congratulations! You are riding the wave of success!`,
			`You had your moment in the sun! Congrats!`,
			`You are a true legend and inspiration to us all!`,
			`Congratulations on your great win! The sky's the limit!`,
			`Wow! Way to go!`,
			`It's not how big you are. It's how big you play!`,
			`I was a hundred percent sure that you'd be the winner!`,
			`Your performance was stunning!`,
			`You are a CHAMPION! Your legend grows!`,
			`Congratulations, you have climbed to the top! Great job!`,
			`You are amazing! Wishing you many more years of success!`,
			`Be Strong when you are week, be Brave when you are scared and be humble when you are victorious.`,
			`A buddy like you deserves a viral congratulatory message. Keep reaching for the stars!`,
			`One machine can do the work of many ordinary man, but no machine can do the work of one extraordinary man.`,
			`Behind every professional success of the company there is always a beautiful mind like yours!`,
			`If Oscars were given for a job well done, I'd nominate you! `,
			`Congratulations on your success! You have made us all proud. Keep up the good work!`,
			`No matter how big a crowd may be, a person like you always stands out!`,
			`I always knew you that you have what it takes to be a real winner.`,
			`You are our shining star!`,
			`Today you conquer your fear and win your race.`,
			`Pain is only temporary but victory is forever.`,
			`Winners don't wait for chances, they take them`,
			`Champions aren't made in the gyms. Champions are made from something they have deep inside them -- a desire, a dream, a vision.`,
			`You don't always get what you wish for, you get what you work for`,
			`Together Everyone Achieves More (TEAM)`,
			`Ability may get you to the top, but it takes character to keep you there.`,
			`Some people dream of success...others stay awake to achieve it.`
		]
	}
}
