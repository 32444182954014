import React from 'react';
import global from './global';
import activities from './activities';
import { STATUS_QUALIFIED, STATUS_UNQUALIFIED, STATUS_REJECTED, STATUS_BLOCKED, STATUS_CLOSED } from '../../config/profile';

export default {
	title: `Gestion du catalogue`,
	search: {
		choose: {
			label: ``,
			profile: `Profil`,
			brand: `Marque`,
			media: `Média`,
			collective: `Collectif`,
			other: `Autre`,
			all: `Tout`,
		},
		noResult: `Désolé, votre recherche n'a retourné aucun résultat`,
		profile: `Profil`,
		link: `Lier`,
		totalResults: nb => (<div><span>{nb}</span> résultat{nb > 1 ? 's' : ''}</div>),
		result: {
			username: "Username",
			uid: "UID",
		},
		tooltips: {
			discovery: "Discovery",
			influence: {
				visible: "Visible sur influence",
				invisible: "Non visible sur influence",
			},
		},
	},
	filters: {
		popover: {
			title: `Filtres`,
			reset: `Reset`,
		},
		fields: {
			socialNetworks: {
				label: `Réseaux sociaux`,
				...global.networks
			},
			activities: {
				label: `Activités / Métiers`,
				search: global.search,
				more: global.select.more,
				add: `+ Ajouter`,
				...activities,
			},
			genders: {
				...global.genders,
				label: `Genres`,

			},
			labelProfileState: {
				label: `Etat du profil`,
			},
			profileStatut: {
				label: `Statut :`,
				[STATUS_QUALIFIED]: `Accepté`,
				[STATUS_REJECTED]: `Refusé`,
				[STATUS_UNQUALIFIED]: `A traiter`,
				[STATUS_BLOCKED]: `Bloqué`,
				[STATUS_CLOSED]: `Supprimé`,
				needReview: `A revoir`,
			},
			profileMember: {
				label: 'Inscription :',
				registered: `Membre`,
				contactable: `Contactable`,
				hasCommunityAccess: `Membre Community`,
				invalidEmail: `Email invalide`,
				missingEmail: `Email manquant`,
				mustClient: `Must Client`,
				enlisted: `Mobilisation lié au coronavirus`,
			},
			subscribedToStatReports: {
			  label: "Abonnements",
			  subscribedToStatsReport: 'Abonné stat report',
			  unsubscribedFromStatsReport: 'Désabonné stat report',
			},
			labelEngineInfluence: {
				label: 'Moteur influence',
			},
			influenceCompletion: {
				label: `Complétion :`,
				complete: `Complet`,
				uncomplete: `Incomplet`,
			},
			influenceActivation: {
				label: `Activation :`,
				activated: `Activé`,
				deactivated: `Désactivé`,
			}
		}
	},
	onboarding: {
		welcome: `Utilisez le moteur de recherche sur votre gauche pour afficher et éditer le profil d'une personnalité`,
		or: `ou`,
		create: {
			profile: `Créez une nouvelle personnalité`,
			brand: `Créez une nouvelle marque`,
			media: `Créez un nouveau média`,
			collective: `Créez un nouveau collectif`,
		}
	}
};
