import { NETWORKS } from './global';
import { STATUS } from './profile';
import activities from '../locales/fr-FR/activities';

export const FILTERS = {
	socialNetworks: {
		type: 'checkboxes',
		datas: NETWORKS,
		default: [],
	},
	genders: {
		type: "checkboxes",
		datas: ['male', 'female', 'other'],
		default: [],
	},
	activities: {
		type: "select-multiple",
		datas: Object.keys(activities),
		default: [],
		params: {
			isActivity: true,
		}
	},
	labelProfileState: {
		isLabel: true,
	},
	profileStatut: {
		type: "checkboxes",
		datas: [...STATUS, 'needReview'],
		default: [],
		params: {
			className: 'label--line',
			sort: false,
		}
	},
	profileMember: {
		type: "checkboxes",
		datas: ['registered', 'contactable', 'hasCommunityAccess', 'mustClient', 'invalidEmail', 'missingEmail', 'enlisted'],
		default: [],
		params: {
			className: 'label--line',
		}
	},
	subscribedToStatReports: {
		type: "checkboxes",
		datas: ['subscribedToStatsReport', 'unsubscribedFromStatsReport'],
		default: [],
		params: {
			className: 'label--line',
		}
	},
	labelEngineInfluence: {
		isLabel: true,
	},
	influenceCompletion: {
		type: "checkboxes",
		datas: ['complete', 'uncomplete'],
		default: [],
		params: {
			className: 'label--line',
		}
	},
	influenceActivation: {
		type: "checkboxes",
		datas: ['activated', 'deactivated'],
		default: [],
		params: {
			className: 'label--line',
		}
	}
}

const CATALOG_FILTERS = [
  'socialNetworks',
  'genders',
  'activities',
  'labelProfileState',
  'profileStatut',
  'profileMember',
  'subscribedToStatReports',
  'labelEngineInfluence',
  'influenceCompletion',
  'influenceActivation',
];

export const catalogFilters = CATALOG_FILTERS.reduce(
  (p, c) => Object.assign(p, { [c]: FILTERS[c] }),
  {},
);

const _defaultFilters = CATALOG_FILTERS.reduce(
  (p, c) => Object.assign(p, !c.isLabel ? { [c]: FILTERS[c].default } : null),
  {},
);
export const defaultFilters = {
  profile: _defaultFilters,
  collective: _defaultFilters,
  brand: _defaultFilters,
  media: _defaultFilters,
  other: _defaultFilters,
  all: _defaultFilters,
};