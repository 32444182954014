import { NETWORKS } from './global';

export const FILTERS = {
	network: {
		type: 'select',
		datas: ['all', ...NETWORKS],
		default: 'all',
		params: {
			isNetwork: true,
			searchable: false,
		}
	}
}
