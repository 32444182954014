import React, { Component } from 'react';

class ModalLayout extends Component {

	ref = React.createRef();

	getHeader() {
		return null;
	}

	getBody() {
		return null;
	}

	getFooter() {
		return null;
	}

	render() {

		const header = this.getHeader();
		const body = this.getBody();
		const footer = this.getFooter();

		return (
			<div className={`modal-content ${this.props.className}`} ref={this.ref}>
				{ header && (<div className="modal-content-header"> { header } </div>) }
				{ body && (<div className="modal-content-body"> { body } </div>) }
				{ footer && (<div className="modal-content-footer"> { footer } </div>) }
			</div>
		);
	}
}

ModalLayout.defaultProps = {
	className: '',
}

export default ModalLayout;
