export const TABS = [
	{
		key: 'default',
		fields: ['firstName', 'lastName', 'stageName', 'gender', 'birthdate', 'nationalities', 'hr', 'phone', 'email', 'otherEmails', 'hr', 'activities', 'socialNetworks', 'hr', 'addresses', 'hr', 'website', 'hr', 'keyFacts', 'shortDescription', 'description', 'longDescription'],
		required: []
	},
	{
		key: 'influence',
		fields: ["enlisted", "firstName", "lastName", "stageName", "gender", "activities", "themesInfluence", "socialNetworks", "influencingCountries", "influencingCountriesAuto"],
		required: ["gender", "socialNetworks"]
	},
];


export const DISCOVERY = {
	key: 'influence',
	fields: ["status", "hr", "firstName", "lastName", "stageName", "gender", "activities", "themesInfluence", 'hr', "socialNetworks", "socialNetworksSuggestions", "profilesSuggestions", "hr", "influencingCountries", "hr", 'birthdate', 'nationalities', 'hr', 'phone', 'otherEmails', 'hr', 'addresses', 'hr', 'website', 'hr', 'keyFacts', 'shortDescription', 'description', 'longDescription'],
	required: ["gender", "socialNetworks"]
};

export const ALL = ["firstName", "lastName", "stageName", "gender", "activities", "themesInfluence", "socialNetworks", "influencingCountries", 'birthdate', 'nationalities', 'phone', 'email', 'otherEmails', 'addresses', 'website', 'keyFacts', 'shortDescription', 'description', 'longDescription'];


export const STATUS_QUALIFIED = "qualified";
export const STATUS_UNQUALIFIED = "unqualified";
export const STATUS_REJECTED = "rejected";
export const STATUS_BLOCKED = "blocked";
export const STATUS_DELETED = "deleted";
export const STATUS_CLOSED = "closed";
export const STATUS = [STATUS_QUALIFIED, STATUS_UNQUALIFIED, STATUS_REJECTED, STATUS_BLOCKED, STATUS_CLOSED];
