import * as constants from '../../constants/insideSales';

export default {
	title: "Télévente",

  adminName: "Nom de l'AE",
  adminSearchPlaceholder: "Rechercher un admin...",

  [constants.QUALIF_STATUS_ONGOING]: "En cours",
  [constants.QUALIF_STATUS_ABANDONED]: "Renoncement",
  [constants.QUALIF_STATUS_PROJECT]: "Projet",

  startDatePlaceholderText: "Date de début",
  endDatePlaceholderText: "Date de fin",

  dates: "Dates",
  reset: "Reset",

  creationDate: "Date de création",
  sales: "Sales",
  company: "Entreprise",
  status: "Statut",
	accountInfo: "Informations générales du compte",
	projectInfo: "Informations générales du projet",
	eventDetails: "Détails de l'évènement",
	conference: "Conférence",
	profileChoice: "Choix de la personnalité",
	bncInfos: "Informations B&C",
  unassigned: "< Non assigné >",

  yes: "Oui",
  no: "Non",
  projectConfirmation: "Êtes-vous sûr de vouloir créer un projet ?",
  rejectConfirmation: "Êtes-vous sûr de vouloir renoncer à cette qualification ?",
	save: "Sauvegarder",
	saveAndCreateProject: `Sauvegarder & créer le projet`,
	reject: "Renoncer",
	clear: "Effacer",
	linkToProject: "Lien vers le projet",
	createProject: "Créer un projet",
	invalidFormMessage: "Qualification incomplète",
	cancel: "Annuler",
	useAmPm: false,

	contactInfo: "Informations contact",
	contactType: "Type de contact",
	firstName: "Prénom",
	lastName: "Nom",
	email: "Email",
	phone: "No direct",
	positionTitle: "Poste",
	contactColor: "Couleur contact",
	contactComments: "Commentaires",

	companyName: "Entreprise",
	companyActivity: "Secteur d'activité",
	companyClient: "Client/Marque",
	companyDemandType: "Nature de la demande",
	companyHistory: "Historique",

	projectName: "Nom du projet",
	context: "Contexte",
	objectives: "Objectifs",

	date: "Date",
	dateComment: "Précision sur la date",
	duration: "Heure d'arrivée souhaitée",
	city: "Ville",
	country: "Pays",
	participantsProfiles: "Profils des participants",
	participantsNumber: "Nombre de participants",
	communication: "Communication",
	logistic: "Logistique à prévoir",
	budget: "Budget",

	conferenceThemes: "Thèmes",
	conferenceFormat: "Format",
	conferenceDuration: "Durée de la prestation",

	consideredProfiles: "Profils envisagés",
	profileCriteria: "Critères de sélection",
	profileExperience: "Expérience avec des célébrités",
	hiredProfiles: "Personnalités intervenues",

	responseDeadline: "Date de retour",
	signatureDeadline: "Date de prise de décision",
	decisionMaker: "Décisionnaire",
	opponents: "Concurrence",
	decisionCriteria: "Critères de décision",
	leadOrigin: "Origine du lead",

  createQualif: "Créer une qualification",

	contactTypes: {
		[constants.CONTACT_ADVERTISER]: "Annonceur",
		[constants.CONTACT_COM_AGENCY]: "Agence de communication",
		[constants.CONTACT_MEDIA_AGENCY]: "Agence média"
	},

	contactColors: {
		[constants.CONTACT_GREEN_COLOR]: "Vert",
	  [constants.CONTACT_ORANGE_COLOR]: "Orange",
	  [constants.CONTACT_YELLOW_COLOR]: "Jaune",
	  [constants.CONTACT_RED_COLOR]: "Rouge"
	},

	companyActivities: {
		state:	"Administration centrale / Ministère / Etat",
		aero:	"Aéronautique",
		agency:	"Agence",
		food:	"Agroalimentaire / Nutrition",
		animal_food:	"Alimentation Animale",
		ikea:	"Ameublement / Décoration",
		social:	"Association / Humanitaire / Social",
		insurance:	"Assurance / Mutuelle",
		bullshit:	"Audit / Conseil / Recrutement / Formation / Etudes",
		lessor:	"Bailleurs Centres Commerciaux",
		bank:	"Banque",
		beauty:	"Beauté / Soins / Hygiène",
		alcohols:	"Boissons (alcool)",
		drinks:	"Boissons (sans alcool)",
		construction:	"BTP",
		mall:	"Centres Commerciaux",
		sport_clubs:	"Clubs Sportifs / Fédérations",
		collective:	"Collectivités locales",
		cci:	"CCI",
		sport_complex:	"Complexes sportifs et événementiels",
		tesla:	"Constructeur Auto / Moto",
		museum:	"Culture / Musées",
		distribution:	"Distribution spécialisée",
		edition:	"Editions",
		education:	"Education",
		darty:	"Electroménager",
		eletronics:	"Electronique Grand Public",
		energy:	"Energie",
		environment:	"Environnement",
		auto_equipment:	"Equipementier Auto / Moto",
		sport_equipment:	"Equipementier sportif",
		event:	"Evénementiel",
		pecore:	"Exploitation agricole",
		bob:	"Fabricant Bricolage / Jardinage",
		desk:	"Fournitures de bureau",
		carrefour:	"Grande Distribution / Supermarchés",
		wholesale:	"Grossiste",
		luxe:	"Groupe de Luxe",
		capitalism_group:	"Groupe Grande consommation",
		clothes:	"Habillement / Accessoires",
		hardware:	"Hardware",
		clocks:	"Horlogerie",
		hotels:	"Hôtellerie / Restauration",
		shell_corporation:	"Import / Export",
		heavy:	"Industrie lourde",
		toys:	"Jeux / Jouets",
		casinos:	"Jeux d'argent",
		video_games:	"Jeux Vidéos",
		jewelery:	"Joaillerie / Bijoux",
		center_park:	"Loisirs / Parcs",
		brigitte_bardot:	"Maroquinerie",
		btp_materials:	"Matériaux de construction",
		print:	"Média presse (print, web)",
		radio:	"Média Radio",
		television:	"Média TV",
		cerise:	"Mutuelle",
		other:	"Autres / NSP",
		professionals:	"Ordres professionnels",
		perfumes:	"Parfums",
		movies:	"Production / Distribution (filsm, séries)",
		ads:	"Production publicitaire",
		mr_propre:	"Produits d'entretien",
		hygiene:	"Produits d'hygiène",
		capitalism_products:	"Produits grande consommation",
		real_estate:	"Promotion immobilière",
		ad_agency:	"Régie publicitaire",
		sfefane_plaza:	"Réseau agences immobilières",
		salons:	"Salon",
		pharmacy:	"Santé / Pharma",
		sector:	"Secteur",
		pro_services:	"Services aux entreprises",
		perso_services:	"Services aux particuliers",
		finance:	"Services financiers",
		software:	"Software (logiciels / SaaS)",
		evil:	"SSII",
		kikoolol:	"Startup",
		mai_68:	"Syndicats professionnels",
		telecom:	"Télécommunications",
		travel:	"Tourisme / Voyage",
		transport:	"Transport / Logistique",
		utilities:	"Utilities (eau, propreté)",
	},

	companyDemandTypes: {
		[constants.PROSPECT_NEW_DEMAND_TYPE]: "Prospect - 1ère opportunité",
		[constants.PROSPECT_OLD_DEMAND_TYPE]: "Prospect - Existant",
		[constants.CLIENT_SAME_DEMAND_TYPE]: "Client - Meme type de campagne",
		[constants.CLIENT_OTHER_DEMAND_TYPE]: "Client - Autre type de campagne",
		[constants.CLIENT_RENEWAL_DEMAND_TYPE]: "Client - Renewal",
		[constants.CLIENT_UPSELL_DEMAND_TYPE]: "Client - Upsell"
	},

	objectivesNames: {
		[constants.UNIQUE_EVENT_OBJECTIVE]: "Rendre l'évènement unique",
	  [constants.ATTRACT_OBJECTIVE]: "Attirer les participants",
	  [constants.CRED_STREET_EVENT_OBJECTIVE]: "Crédibiliser l'évènement",
	  [constants.FEDERATION_OBJECTIVE]: "Fédérer les participants",
	  [constants.MESSAGE_OBJECTIVE]: "Faire passer un message",
	  [constants.MOTIVATION_OBJECTIVE]: "Motiver les participants"
	}
}
