import React from "react";
import { Route, Switch, Redirect } from "react-router";

import routes from "config/routes";

import Login from "./Login";
import RequestPasswordReset from "./RequestPasswordReset";

import styles from "./Auth.module.scss";

const Auth = () => {
  return (
    <div className={styles.container}>
      <Switch>
        <Route exact path={routes.auth.login} component={Login} />
        <Route
          exact
          path={routes.auth.requestPasswordReset}
          component={RequestPasswordReset}
        />
        <Redirect to={routes.auth.login} />
      </Switch>
    </div>
  );
};

export default Auth;
