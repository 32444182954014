import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as CATALOG from '../constants/catalog';
import * as PROFILE from '../constants/profile';
import { defaultFilters } from '../config/catalog';

const initialState = {
	search: {
		type: 'profile',
		loading: false,
		value: '',
		results: null,
		totalResults: 0,
		filters: defaultFilters,
		page: 0,
	},
};

const reducers = {
	[CATALOG.SEARCH_UPDATE]: (state, {payload: {value}}) => update(state, {
		search: {
			page: { $set: 0},
			value: { $set: value }
		}
	} ),
	[CATALOG.SEARCH_REQUEST]: state => update(state, {
		search: {
			loading: { $set: false }
		}
	} ),
	[CATALOG.SEARCH_COMPLETE]: (state, {payload:{profiles:{results, total}}}) => update(state, {
		search: {
			loading: { $set: false },
			results: { $set: results },
			totalResults: { $set: total }
		}
	} ),
	[CATALOG.TYPE_UPDATE]: (state, {payload:type}) => update(state, {
		search: {
			page: { $set: 0 },
			type: { $set: type },
		}
	} ),
	[PROFILE.DELETE]: (state, {payload:{profile:{id}}}) => update(state, {
		search: {
			results: { $set: state.search.results ? state.search.results.filter(p => p.id !== id) : null }
		}
	} ),
	[CATALOG.FILTER_UPDATE]: (state, {payload:{value, filter, type}}) => update(state, {
		search: {
			page: { $set: 0 },
			filters: {
				[type]: {
					[filter]: { $set: value }
				}
			}
		}
	} ),
	[CATALOG.FILTERS_RESET]: (state, {payload:type}) => update(state, {
		search: {
			page: { $set: 0 },
			filters: {
				[type]: { $set: defaultFilters[type] }
			}
		}
	} ),
	[CATALOG.PAGE_UPDATE]: (state, {payload:page}) => update(state, {
		search: {
			page: { $set: page },
		}
	} ),
}


export default createReducer(initialState, reducers);
