const errorMessages = {
  unconfirmed: `Email en attente de confirmation`,
  isAlreadyConfirmed: `Email déjà confirmé`,
  forbidden: `Vous n'avez pas accès à ce service`,
  notActive: `Votre compte est désactivé. Merci de nous contacter via support@kolsquare.com`,
  maintenance: `Notre application est en cours de maintenance... Veuillez réessayer dans quelques minutes et nous excuser pour la gêne occasionnée`,
  connectionError: `Connexion...`,
  unknown: `Une erreur critique est survenue... Veuillez réessayer dans quelques minutes et nous excuser pour la gêne occasionnée`,
  nonActivated: `Votre compte n'est pas activé. Activez votre compte avec l'e-mail d'activation que vous avez reçu.`,
};

export default {
  login: {
    email: `Email`,
    password: `Mot de passe`,
    askIfPasswordForgotten: `Mot de passe oublié ?`,
    login: "Se connecter",
    loading: "En cours de connexion ...",
    errors: {
      ...errorMessages,
      invalidCredentials: `Email et/ou mot de passe incorrect(s)`,
    },
  },
  requestPasswordReset: {
    askIfPasswordForgotten: `Mot de passe oublié ?`,
    login: "Se connecter",
    email: `Email`,
    loading: `Réinitialisation de mot de passe ...`,
    submitted: "Email envoyé ! Cliquez pour renvoyer",
    requestPasswordReset: "Réinitialiser mon mot de passe",
    instruction:
      "Renseignez votre email. Vous recevrez un lien pour réinitialiser votre mot de passe",
    errors: {
      ...errorMessages,
      invalidCredentials: `Email incorrect`,
    },
  },
};
