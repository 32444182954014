import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as FEATURES from '../constants/features';

const initialState = {
	allFeatures: {},
    allPackages: {},
};

const reducers = {
	[FEATURES.FEATURES_LOADED]: (state, {payload}) => update(state, {
        $set: payload
    } ),
    [FEATURES.FEATURE_UPDATE]: (state, {payload:feature}) => update(state, {
        allFeatures: {
            [feature.id]: {
                $merge: feature
            }
        }
    } ),
    [FEATURES.PACKAGE_UPDATE]: (state, {payload:pack}) => update(state, {
        allPackages: {
            [pack.id]: {
                $merge: pack
            }
        }
    } ),
}


export default createReducer(initialState, reducers);
