export default {
	auth: {
		home: '/auth',
		login: '/auth/login',
		requestPasswordReset: '/auth/reset-password',
	},
	catalog: {
		home: '/catalog',
		new: {
			profile: '/catalog/profiles/new',
			brand: '/catalog/brands/new',
			media: '/catalog/medias/new',
			collective: '/catalog/collectives/new',
		},
		all: '/catalog/profiles/',
		profile: '/catalog/profiles/',
		brand: '/catalog/brands/',
		media: '/catalog/medias/',
		collective: '/catalog/collectives/',
		other: '/catalog/others/',
	},
	coverage: {
		home: '/coverage',
	},
	discovery: {
		home: '/discovery',
		profile: '/discovery/profiles/',
	},
	insideSales: {
		home: '/inside-sales',
		form: `/inside-sales/form/`,
		forms: `/inside-sales/forms/`,
	},
	logs: {
		home: `/logs`,
	},
	accounts: {
		home: '/accounts',
		newUser: '/accounts/users/new',
		newTeam: '/accounts/teams/new',
		team: '/accounts/teams/',
		user: '/accounts/users/',
		admin: '/accounts/admins/',
		company: '/accounts/company/',
	},
	giftbox: {
		home: '/features',
	},
	banner: {
		home: '/banner',
	},
	phraseUsers: {
		home: '/phrase-users',
	},
	industryRanking: {
		home: '/industry-ranking',
	},
}
