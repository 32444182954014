import React, { lazy, Suspense } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const LoadedComponent = lazy(() => import('./Banner'));

export default () => (
  <Suspense fallback={<LinearProgress color="secondary" />}>
    <LoadedComponent />
  </Suspense>
);
