// Is event triggered outside DOM
export const isEventOutside = (e, dom) => {
	let current = e.target;

	const componentNode = dom;

	while(current.parentNode) {
		if (current === componentNode) {
			return false;
		}
		current = current.parentNode;
	}

	if (current !== document) {
		return false;
	}

	return true;
}

// Is DOM in viewport
export const isInViewport = (dom, offset=0) => {
	const vh = window.innerHeight;
    const posY = dom.getBoundingClientRect().top;
	const relPos = posY - vh - offset;

	return (relPos < 0);
}
