import React from 'react';

export default ({ fullName, stageName, name, firstName, lastName }) => {
	if (name && name !== '') {
		return (
			<span className="profile-name-inline">
				<span className="nowrap">{name}</span>
			</span>
		);
	}

	if (firstName) {
		return (
			<span className="profile-name-inline">
				<span className="nowrap">{firstName} {lastName}</span>
			</span>
		);
	}

	if (stageName) {
		if (fullName) {
			return (
				<span className="profile-name-inline">
					<span className="nowrap">{stageName}</span> (<span className="nowrap">{fullName}</span>)
				</span>
			);
		}
		else {
			return <span className="nowrap">{stageName}</span>;
		}
	}
	else {
			return <span className="nowrap">{fullName}</span>;
	}
}
