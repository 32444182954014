export const QUALIF_STATUS_ONGOING    = 'ongoing';
export const QUALIF_STATUS_ABANDONED  = 'abandoned';
export const QUALIF_STATUS_PROJECT    = 'project';
export const QUALIF_STATUS = [
  QUALIF_STATUS_ONGOING,
  QUALIF_STATUS_ABANDONED,
  QUALIF_STATUS_PROJECT
]

export const DATE_PICKER_FORMAT   = 'DD/MM/YYYY';

export const SEARCH_UPDATE        = 'qualif::search::update';
export const SEARCH_COMPLETE      = 'qualif::search::complete';
export const SEARCH_ONCHANGE      = 'qualif::search::onchange';
export const SEARCH_RESET         = 'qualif::search::reset';
export const FILTERS_RESET        = 'qualif::search::reset';
export const GET_ADMINS_LIST      = 'qualif::admins::list';

export const CONTACT_GREEN_COLOR   = 'green';
export const CONTACT_YELLOW_COLOR  = 'yellow';
export const CONTACT_ORANGE_COLOR  = 'orange';
export const CONTACT_RED_COLOR     = 'red';
const CONTACT_COLORS = [
  CONTACT_GREEN_COLOR,
  CONTACT_ORANGE_COLOR,
  CONTACT_YELLOW_COLOR,
  CONTACT_RED_COLOR
];

export const CONTACT_ADVERTISER    = 'advertiser';
export const CONTACT_COM_AGENCY    = 'com_agency';
export const CONTACT_MEDIA_AGENCY  = 'media_agency';
const CONTACT_TYPES = [
  CONTACT_ADVERTISER,
  CONTACT_COM_AGENCY,
  CONTACT_MEDIA_AGENCY
];

export const PROSPECT_NEW_DEMAND_TYPE     = 'prospect_new';
export const PROSPECT_OLD_DEMAND_TYPE     = 'prospect_old';
export const CLIENT_SAME_DEMAND_TYPE      = 'client_same';
export const CLIENT_OTHER_DEMAND_TYPE     = 'client_other';
export const CLIENT_RENEWAL_DEMAND_TYPE   = 'client_renewal';
export const CLIENT_UPSELL_DEMAND_TYPE    = 'client_upsell';
const COMPANY_DEMAND_TYPES = [
  PROSPECT_NEW_DEMAND_TYPE,
  PROSPECT_OLD_DEMAND_TYPE,
  CLIENT_SAME_DEMAND_TYPE,
  CLIENT_OTHER_DEMAND_TYPE,
  CLIENT_RENEWAL_DEMAND_TYPE,
  CLIENT_UPSELL_DEMAND_TYPE
];

export const UNIQUE_EVENT_OBJECTIVE      = 'unique_event';
export const ATTRACT_OBJECTIVE           = 'participant_recruitment';
export const CRED_STREET_EVENT_OBJECTIVE = 'added_authority';
export const FEDERATION_OBJECTIVE        = 'participant_federate';
export const MESSAGE_OBJECTIVE           = 'convey_message  ';
export const MOTIVATION_OBJECTIVE        = 'participant_motivation';
const PROJECT_OBJECTIVES = [
  UNIQUE_EVENT_OBJECTIVE,
  ATTRACT_OBJECTIVE,
  CRED_STREET_EVENT_OBJECTIVE,
  FEDERATION_OBJECTIVE,
  MESSAGE_OBJECTIVE,
  MOTIVATION_OBJECTIVE
]

const COMPANY_ACTIVITIES = [
  "state",
  "aero",
  "agency",
  "food",
  "animal_food",
  "ikea",
  "social",
  "insurance",
  "bullshit",
  "lessor",
  "bank",
  "beauty",
  "alcohols",
  "drinks",
  "construction",
  "mall",
  "sport_clubs",
  "collective",
  "cci",
  "sport_complex",
  "tesla",
  "museum",
  "distribution",
  "edition",
  "education",
  "darty",
  "eletronics",
  "energy",
  "environment",
  "auto_equipment",
  "sport_equipment",
  "event",
  "pecore",
  "bob",
  "desk",
  "carrefour",
  "wholesale",
  "luxe",
  "capitalism_group",
  "clothes",
  "hardware",
  "clocks",
  "hotels",
  "shell_corporation",
  "heavy",
  "toys",
  "casinos",
  "video_games",
  "jewelery",
  "center_park",
  "brigitte_bardot",
  "btp_materials",
  "print",
  "radio",
  "television",
  "cerise",
  "other",
  "professionals",
  "perfumes",
  "movies",
  "ads",
  "mr_propre",
  "hygiene",
  "capitalism_products",
  "real_estate",
  "ad_agency",
  "sfefane_plaza",
  "salons",
  "pharmacy",
  "sector",
  "pro_services",
  "perso_services",
  "finance",
  "software",
  "evil",
  "kikoolol",
  "mai_68",
  "telecom",
  "travel",
  "transport",
  "utilities"
]

export const CREATE_REQUEST = 'qualif::create::request'
export const CREATION_DONE  = 'qualif::creation::done'

export default {
  CONTACT_TYPES,
  CONTACT_COLORS,
  COMPANY_ACTIVITIES,
  COMPANY_DEMAND_TYPES,
  PROJECT_OBJECTIVES
}
