import { conf } from 'config/env';
import Cookies from 'universal-cookie';
import * as authApi from 'api/auth';

export const login = ({ email, password }) => async () => {
  try {
    const { error, hasCommunityAccess } = await authApi.login({ email, password });

    /**
     * ⚠️⚠️ IMPORTANT ⚠️⚠️
     * disallow allow KOLs access to Influence
     * 
     */
    if (hasCommunityAccess) {
      const cookies = new Cookies();
      cookies.remove(conf.cookieToken, { path: '/', domain: conf.cookieHostname, sameSite: true });
      return { error: 'invalidCredentials' };
    }

    if (error) return { error };
    window.location.href = conf.urls.app;
    return { sucess: true };
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const requestPasswordReset = ({ email }) => async () => {
  try {
    const { error } = await authApi.requestPasswordReset({ email });
    if (error) return { error };
    return { sucess: true };
  } catch (err) {
    return { error: 'unknown' };
  }
}
