import { createAction} from 'redux-actions';

import * as api from '../api/features';
import mapper from '../mappers/features';
import * as FEATURES from '../constants/features';

const featuresAndPackagesLoaded = createAction(FEATURES.FEATURES_LOADED);
const storeUpdateFeature = createAction(FEATURES.FEATURE_UPDATE);
const storeUpdatePackage = createAction(FEATURES.PACKAGE_UPDATE);

export const getFeaturesAndPackages = userFeatures => async dispatch => {

    const [resFeatures, resPackages] = await Promise.all([
        userFeatures.listFeatures ? api.getFeatures() : null,
        userFeatures.listPackages ? api.getPackages() : null,
    ]);

    const allFeatures = resFeatures ? mapper.getFeatures.fromApi(resFeatures) : {};
    const allPackages = resPackages ? mapper.getPackages.fromApi(resPackages) : {};

    const obj = { allFeatures, allPackages };

    dispatch(featuresAndPackagesLoaded(obj));

    return obj;
}

export const getFeatureUsers =
  ({ featureId, page = 0, perPage = 10 }) =>
  async (dispatch, getState) => {
    const response = await api.getFeatureUsers({ featureId, page, perPage });
    const mapped = mapper.getFeatureOrPackageUsers.fromApi(response);

    const { [featureId]: feature } = getState().features.allFeatures;
    dispatch(
      storeUpdateFeature({
        ...feature,
        users: mapped,
      })
    );
  };

export const getPackageUsers =
  ({ packageId, page = 0, perPage = 10 }) =>
  async (dispatch, getState) => {
    const response = await api.getPackageUsers({ packageId, page, perPage });
    const mapped = mapper.getFeatureOrPackageUsers.fromApi(response);

    const { [packageId]: pack } = getState().features.allPackages;
    dispatch(
      storeUpdatePackage({
        ...pack,
        users: mapped,
      })
    );
  };
