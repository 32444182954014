export default {
  title: `Features & Packages`,
  user: `User`,
  admin: `Admin`,
  pagination: ({ page, total, perPage }) =>
    `Page ${page + 1} sur ${Math.ceil(total / perPage)}`,
  labels: {
    id: "ID",
    active: "Activé",
    description: "Description",
    features: "Features actives",
    users: "Utilisateurs",
  },
  users: {
    createdAt: (createdAt) => `Attribué le ${createdAt}`,
    title: {
      packages: `Utilisateurs du package`,
      features: `Utilisateurs de la feature`,
    },
    labels: {
      id: `ID`,
      description: `Description`,
      users: `Utilisateurs`,
    },
  },
  features: {
    title: `Features`,
    tooltips: {
      enabled: `feature activé`,
      disabled: `feature désactivé`,
      membersCount: ` utilisateurs ont cette feature`,
    },
  },
  packages: {
    title: `Packages`,
    features: `Features dans le package`,
    tooltips: {
      enabled: `package activé`,
      disabled: `package désactivé`,
      featuresInPackage: ` features dans le package`,
      membersCount: ` utilisateurs ont ce package`,
    },
  },
};
