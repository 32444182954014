import React from 'react';
import Name from '../../components/ui/Name';
import profile from './profile';

export default {
	fields: {
		...profile.fields,
		fullName: {
			label: `Nom du collectif`,
			placeholder: ``,
			helper: ``
		}
	},
	tab: profile.tab,
	tabs: profile.tabs,
	modales: {
		deleteProfile: {
			title: `Supprimer un collectif`,
			body: props => (<div>Êtes-vous sûr de vouloir supprimer <span className="modale-profile-name"><Name {...props } /></span> ?<br />Attention cette action est irréversible et ne pourra être annulée.</div>),
			yes: `Supprimer`,
			no: `Annuler`
		},
		addProfile: {
			title: `Ajouter un collectif`,
			cancel: `Annuler`,
			confirm: `Créer le collectif`,
			matchingProfiles: `Les collectifs suivants peuvent correspondre à celui que vous souhaitez créer. Si c'est le cas, merci d'éditer la fiche du collectif correspondant et ne pas en créer un nouveau`,
			cgv: `Je certifie que le collectif que je veux créer n'existe pas déjà.`,
			success: `Collectif créée avec succès ! Vous pouvez maintenant éditer sa fiche !`,
			search: {
				noResult: ``,
			}
		},
		confirmLightMerge: {
			title: `Lier un compte social à un collectif`,
			body: profile => (<div>Êtes-vous sûr(e) de vouloir lier ce compte social à <span className="modale-profile-name"><Name {...profile } /></span> ?</div>),
			yes: `Oui, lier`,
			no: `Annuler`
		},
		merge: {
			title: `Fusionner avec...`,
			cancel: `Annuler`,
			matchingProfiles: `Sélectionnez le collectif avec lequel vous souhaitez fusionner.`,
			merge: `Fusionner`,
			search: {
				noResult: ``,
			}
		},
		confirmMerge: {
			title: `Sélectionner les champs à conserver`,
			confirm: `Fusionner`,
			cancel: `Annuler`,
			emptyProfile: `Champs libres`
		},
		mergeError: {
			title: 'Fusion impossible',
			body: `Désolé, ces 2 collectifs correspondent à des célébrités distinctes, ou des membres community, et ne peuvent donc pas être fusionnés.`,
			ok: `Ok`,
		},
	}
}
