/*
--- getAppInit
	--- fromApi	( Api > App )
{											{
	"id": Number,								id: Number,
	"first_name": String,						firstName: String,
	"last_name": String,						lastName: String,
	"picture_url": String,						picture: String,
	discovery_flags: Array						discoveryFlags: Array
}											}
*/
const getAppInit = {
	fromApi: ({id, first_name, last_name, picture_url, discovery_flags, discovery_partitions, account_executives, features, feature_groups}) => ({
		user: {
			id,
			firstName: first_name,
			lastName: last_name,
			picture: picture_url,
			discoveryFlags: discovery_flags,
			discoveryPartitions: discovery_partitions,
			packagesIds: (feature_groups || []),
			rights: features ? {
				insideSales: features.includes('catalog/qualifications'),
				discovery: features.includes('catalog/base'),
				logs: features.includes('catalog/logs'),
				catalog: features.includes('catalog/update_status'),
				accounts: features.includes('catalog/users_and_teams'),
				// dashboard: features.includes('catalog/dashboard'), TOTO: Backend should clean, this feature
				giftbox: features.includes('catalog/features') || features.includes('catalog/feature_groups'),
				listFeatures: features.includes('catalog/features'),
				listPackages: features.includes('catalog/feature_groups'),
				features: features.includes('catalog/manage_features'),
				packages: features.includes('catalog/manage_groups'),
				admins: features.includes('catalog/manage_admins'),
				banner: features.includes('catalog/banner_announcement'),
				phraseUsers: features.includes('catalog/manage_phrase_users'),
				industryRanking: features.includes('catalog/base'),
				coverage: features.includes('catalog/base'),
			} : {
				insideSales: false,
				discovery: false,
				logs: false,
				catalog: false,
				accounts: false,
				giftbox: false,
				features: false,
				packages: false,
				admins: false,
				banner: false,
				phraseUsers: false,
				industryRanking: false,
				coverage: false,
			}
		},
		accountExecutives: account_executives.map(a => ({
			id: a.id,
			firstName: a.first_name,
			lastName: a.last_name,
		})),
	}),
}

export default {
	getAppInit,
};