export const SEARCH_UPDATE = "accounts::search::update";
export const SEARCH_REQUEST = "accounts::search::request";
export const SEARCH_COMPLETE = "accounts::search::complete";
export const LOAD_COMPLETE = "accounts::load::complete";
export const FIELD_UPDATE = "accounts::field::update";
export const FIELDS_UPDATE = "accounts::fields::update";
export const TEAM_ADD_TO = "accounts::team::add-to";
export const TEAM_REMOVE_FROM = "accounts::team::remove-from";
export const WELCOME_EMAIL_SENT = "accounts::welcome-email::sent";
export const COMPANY_LOAD_COMPLETE = "accounts::company::load::complete";
export const COMPANY_LICENCES_LOAD_COMPLETE = "accounts::company::licences::load::complete";
