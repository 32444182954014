import React from 'react';
import Button from '@material-ui/core/Button';

import ModalLayout from '../ModalLayout';

class ErrorMessage extends ModalLayout {

	getHeader() {
		const { lexique, code } = this.props;
		return (
			<div>
				{ lexique.title(code) }
			</div>
		);
	}

	getBody() {
		const { lexique, code } = this.props;
		return (
			<div>
				{ lexique.codes[code] }
			</div>
		);
	}

	getFooter() {
		const { lexique, onAcknowledge, closable } = this.props;
		if (!closable) return null;
		return (
			<div>
				<Button className="btn-flat btn-flat-red" onClick={onAcknowledge}>{lexique.ok}</Button>
			</div>
		);
	}
}


export default ErrorMessage;
