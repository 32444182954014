import React, { Component } from 'react';

import { CLOSE } from '../icons/tools';
import { isEventOutside } from '../../utils/dom';

class ModalEngine extends Component {

	ref = React.createRef();

	componentDidMount() {
		const { closable, disableCloseBackdrop, disabledCloseEscape } = this.props;

		if (closable && !disabledCloseEscape) {
			document.addEventListener('keydown', this.onKeyPressed, false);
		}
		if (closable && !disableCloseBackdrop) {
			document.addEventListener("click", this.checkWhereClicked, false);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyPressed, false);
		document.removeEventListener("click", this.checkWhereClicked, false);
	}

	checkWhereClicked = e => {
		if (isEventOutside(e, this.ref.current)) this.callbackClose();
	}

	onKeyPressed = e => {
		if (e.keyCode === 27) this.callbackClose();
	}

	callbackClose = () => {
		const { onClose } = this.props;
		if (!onClose) {
			console.warn('No close callback defined. Closing Modal should be defined within openModal({onClose:...})'); //eslint-disable-line no-console
			return;
		}
		onClose();
	}

	render() {
		const { content, className, closable } = this.props;
		return (
			<div className={`modal ${className}`} ref={this.ref}>
				{ content }
				{ closable && (
					<button className="modal-close no-btn" onClick={this.callbackClose}>
						<span className="ico ico--medium">{CLOSE}</span>
					</button>
				)}
			</div>
		);
	}
}

ModalEngine.defaultProps = {
	closable: false,
	onClose: null,
	className: ''
}

export default ModalEngine;
