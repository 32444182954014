export const LOAD_REQUEST = "profile::load::request";
export const LOAD_COMPLETE = "profile::load::complete";
export const CHANGES_DISCARD = "profile::changes:discard";
export const FIELD_UPDATE = "profile::field::update";
export const FIELD_UPDATE_BOTH = "profile::field::update-both";
export const SAVE_COMPLETE = "profile::save::complete";
export const DELETE = "profile::delete";
export const SNA_CERTIFY = "profile::sna::certify";
export const SNA_TOGGLE_COLLECT = "profile::sna::collect";
export const SNAS_CERTIFY_ALL = "profile::snas::certify";
export const SNAS_SUGGESTIONS = "profile::snas::suggestions";
