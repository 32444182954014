import { createTheme } from '@material-ui/core/styles'

export default createTheme({
	palette: {
		primary: {
			light: '#67daff',
			main: '#03a9f4',
			dark: '#007ac1',
			contrastText: '#e3f2fd'
		},
		secondary: {
			light: '#ffad42',
			main: '#f57c00',
			dark: '#bb4d00',
			contrastText: '#fff3e0'
		}
	},
	typography: {
		useNextVariants: true,
	},
	overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "0.9em",
            },
        },
		MuiLinearProgress: {
			root: {
				flex: '0 0 8px',
				position: 'sticky',
				top: 0,
				width: '100%',
				height: 8,
				marginBottom: "-8px",
				padding: '0 !important',
				zIndex: 3,
			},
		}
    },
});
