import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as ACCOUNTS from 'constants/accounts';
import * as TEAMS from 'constants/teams';

const initialState = {
	search: {
		loading: false,
		results: [],
		total: 0,
		value: '',
		page: 0,
	},
	account: {},
	team: {
		name: "",
		features: []
	},
	database: {},
	company: {
		licences: [],
	},
};

const reducers = {
	[ACCOUNTS.SEARCH_UPDATE]: (state, {payload:value}) => update(state, {
		search: {
			page: { $set: 0 },
			value: { $set: value }
		}
	} ),
	[ACCOUNTS.SEARCH_REQUEST]: state => update(state, {
		search: {
			loading: { $set: true }
		}
	} ),
	[ACCOUNTS.SEARCH_COMPLETE]: (state, {payload:{results, total}}) => update(state, {
		search: {
			loading: { $set: false },
			results: { $set: results },
			total: { $set: total }
		}
	} ),
	[ACCOUNTS.LOAD_COMPLETE]: (state, {payload:account}) => update(state, {
		account: { $set: account },
		database: { $set: account },
	} ),
	[ACCOUNTS.FIELD_UPDATE]: (state, {payload:{key, value}}) => update(state, {
		account: {
			[key]: { $set: value }
		}
	} ),
	[ACCOUNTS.FIELDS_UPDATE]: (state, {payload: data}) => update(state, {
		account: {
			$set: {
				...state.account,
				...data,
			}
		}
	} ),
	[TEAMS.FIELD_UPDATE]: (state, {payload:{key, value}}) => update(state, {
		team: {
			[key]: { $set: value }
		}
	} ),
	[TEAMS.LOAD_COMPLETE]: (state, {payload:team}) => update(state, {
		team: { $set: team },
		database: { $set: team },
	} ),
	[ACCOUNTS.TEAM_ADD_TO]: (state, {payload:member}) => update(state, {
		team: {
			members: {
				$push: [member]
			}
		},
	} ),
	[ACCOUNTS.TEAM_REMOVE_FROM]: (state, {payload:id}) => update(state, {
		team: {
			members: {
				$set: state.team.members.filter(m => m.id !== id)
			}
		},
	} ),
	[ACCOUNTS.WELCOME_EMAIL_SENT]: state => update(state, {
		account: {
			isWelcomeEmailSent: { $set: true }
		},
	} ),
	[ACCOUNTS.COMPANY_LOAD_COMPLETE]: (state, { payload: company }) => update(state, {
		company: { $merge: company },
	}),
	[ACCOUNTS.COMPANY_LICENCES_LOAD_COMPLETE]: (state, { payload: licences }) =>
		update(state, {
			company: {
				licences: { $set: licences },
			},
		}),
}


export default createReducer(initialState, reducers);
