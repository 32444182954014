import _ from 'lodash';

import fr_FR from './fr-FR';
import { useSelector } from '../utils/redux';

const lexique = { fr_FR };
const defaultLocale = "fr_FR"; // For internal use as fallback. To change user default language, change the value "locale" returned from endpoint api > user.js > appInit

const getLexique = locale => {
	const l = locale.replace('-', '_');
	return lexique[l] ? lexique[l] : lexique[defaultLocale];
}

export default getLexique;

export const availableLocales = _.sortBy(Object.keys(lexique).map(l => ({
																			label: lexique[l].config.label,
																			iso3:  lexique[l].config.iso3,
																			id: lexique[l].config.iso,
																  		})
															  ), ['label']
);


export const getByGender = (locale, gender="male") => {
	const text = locale.split('||');
	if (text.length === 1) return locale;

	return text[gender === "female" ? 1 : 0];
}


export const useI18n = () => {
	const locale = useSelector(({env}) => env.locale);
    return getLexique(locale);
}