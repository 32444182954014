import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Home from "@material-ui/icons/Home";

import { doLogout } from 'actions/env';
import routes from 'config/routes';
import { env, conf } from 'config/env';
import getLexique from 'locales';

import * as ICO from './icons/header';
import MenuIconButton from './ui/MenuIconButton';
import { ReactComponent as LogoKolsquareWhite } from 'svg/logo-kolsquare-white.svg';

const MENU_ITEMS = [
  'coverage',
  'accounts',
  'giftbox',
  'catalog',
  'logs',
  'discovery',
  'insideSales',
  'banner',
  'phraseUsers',
  'industryRanking',
];

class Header extends Component {

	state = { anchor: null }

	getMenuItems = () => {
		const { rights } = this.props;

		return MENU_ITEMS.filter((i) => rights[i]);
	}

	gotoGenesis = () => {
		window.location.href = conf.urls.genesis;
	}

	logout = () => {
		this.props.actions.doLogout();
	}

	render() {
		const { lexique, match:{path}, rights } = this.props;

		if (!rights) return (
			<header className="header with-logo">
				<LogoKolsquareWhite className="header-logo" />
				<div className={`env-indicator ${env}`} />
			</header>
		);

		const allowedItems = this.getMenuItems();

		return (
			<header className="header">
				<ul className="header-ul">
					{ allowedItems.map(i => (
						<li key={i}  className="header-ul-li">
							<MenuLink
								active={path.indexOf(routes[i].home) > -1}
								to={routes[i].home}
								label={lexique.header[i]}
								icon={ICO[i.toUpperCase()]}
							/>
						</li>
						)
					)}
				</ul>
				<ul className="header-ul">
					<li className="header-ul-li button-menu">
						<MenuIconButton
							id='menu-header'
							button={<MoreVertIcon />}
						>
							{rights.accounts && <MenuItem onClick={this.gotoGenesis}>
								<ListItemIcon>
									<Home />
								</ListItemIcon>
								<ListItemText inset primary={lexique.header.gotoGenesis} />
							</MenuItem>}
							<MenuItem onClick={this.logout}>
								<ListItemIcon>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText inset primary={lexique.header.logout} />
							</MenuItem>
						</MenuIconButton>
					</li>
				</ul>
				<div className={`env-indicator ${env}`} />

			</header>
		)
	}
}


const MenuLink = ({active, to, label, icon}) => (
	<NavLink className={`header-item${active ? ' active' : ''}`} activeClassName="current" exact to={to}>
		<span className="header-item-ico ico">{icon}</span>
		<span className="header-item-label">{label}</span>
	</NavLink>
)


const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale),
	rights: env.user.rights
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ doLogout }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
