import React from 'react';

import ModalLayout from '../ModalLayout';
import { randomFromArray } from '../../../utils/random';

const TIMER = 7000;

class ErrorNetworkMessage extends ModalLayout {

	constructor(props) {
		super (props);
		this.state = { sentence: randomFromArray(props.lexique.dyk), ms: TIMER }
	}

	componentDidMount() {
		this.interv = setInterval(this.tickInterval, 50);
	}

	componentWillUnmount() {
		clearInterval(this.interv);
		this.interv = null;
	}

	tickInterval = () => {
		const ms = this.state.ms - 50;

		if (ms <= 0) {
			this.setState({
				sentence: randomFromArray(this.props.lexique.dyk),
				ms: TIMER,
			});
		}
		else {
			this.setState({ms});
		}
	}

	getHeader() {
		const { title } = this.props.lexique;
		return (
			<div>
				{ title }
			</div>
		);
	}

	getBody() {
		const { body } = this.props.lexique;
		const { sentence, ms } = this.state;
		return (
			<div>
				{ body }
				<div className="message message--info full-width prel">
					{ sentence }
					<span className="progress-modale" style={{width: `${100-(ms/TIMER*100)}%`}} />
				</div>
			</div>
		);
	}

	getFooter() {
		return null;
	}
}


export default ErrorNetworkMessage;
