import React from 'react';
import nl2br from 'react-nl2br';
import Button from '@material-ui/core/Button';

import ModalLayout from '../../ui/ModalLayout';

class ConfirmChangePage extends ModalLayout {

	getHeader() {
		const { lexique } = this.props;
		return (
			<div>
				{ lexique.title }
			</div>
		);
	}

	getBody() {
		const { lexique } = this.props;
		return (
			<div>
				{ nl2br(lexique.body) }
			</div>
		);
	}

	getFooter() {
		const { lexique, onConfirm, onDiscard } = this.props;
		return (
			<div>
				<Button className="btn-flat btn-flat-blue" onClick={onDiscard}>{lexique.no}</Button>
				<Button className="btn-flat btn-flat-red" onClick={onConfirm}>{lexique.yes}</Button>
			</div>
		);
	}
}


export default ConfirmChangePage;
