import React from 'react';
import browser from 'browser-detect';

import { openModal, closeModal } from 'actions/env';
import { appsignal } from 'config/appsignal';
import { conf } from 'config/env';
import routes from 'config/routes';
import { getStore, getDispatcher } from 'config/store';
import getLexique from 'locales';

import ErrorMessage from 'components/ui/modales/ErrorMessage';
import ErrorNetworkMessage from 'components/ui/modales/ErrorNetworkMessage';

let isOnline = window.navigator.onLine;
export const setNetworkListener = () => {
	window.addEventListener('online',  onNetworkUpdate);
	window.addEventListener('offline', onNetworkUpdate);
	onNetworkUpdate();
}

export const unsetNetworkListener = () => {
	window.removeEventListener('online',  onNetworkUpdate);
	window.removeEventListener('offline', onNetworkUpdate);
}

const onNetworkUpdate = () => {
	const now = window.navigator.onLine;

	if ((isOnline && !now) || (!isOnline && !now)) {
		// show modal error
		openModaleDidYouKnow();
	}

	if (!isOnline && now) {
		// Hide modal error
		closeModaleDidYouKnow();
	}

	isOnline = now;
}

let isModaleDidYouKnowOpened = false;
export const openModaleDidYouKnow = () => {
	if (isModaleDidYouKnowOpened) return;
	const lexique = getLexique(getStore().env.locale);
	const dispatch = getDispatcher();
	dispatch(openModal({
		content: <ErrorNetworkMessage
					lexique={lexique.global.errors.offline}
				 />,
		closable: false,
		className: 'modal--error',
	}));
	isModaleDidYouKnowOpened = true;
}

export const closeModaleDidYouKnow = () => {
	if (!isModaleDidYouKnowOpened) return;
	const dispatch = getDispatcher();
	dispatch(closeModal());
	isModaleDidYouKnowOpened = false;
}

export const moveToRoot = () => {
	window.location.href = conf.urls.app
}

export const moveToLogin = () => {
  if (!window.location.href.includes(routes.auth.home)) {
    window.location.href = routes.auth.login;
  }
};

export const errorParser = (error, datas) => {
	broadcastError("Runtime Error", error, {...datas, code: 'RUN' });
}

export const errorCatcher = (error, datas) => {
	const dispatch = getDispatcher();
	const lexique = getLexique(getStore().env.locale);

	const code = error && error.response && error.response.status ? error.response.status : datas.code;

	// particular action depending on status Code
	switch (code) {
		case 401:
		case 403:
			moveToLogin();
			return;
		default:
	}

	dispatch(openModal({
		content: <ErrorMessage closable={true} onAcknowledge={moveToRoot} code={code} lexique={lexique.global.errors} />,
		closable: true,
		className: 'modal--error',
		onClose: moveToRoot
	}));

	// make an API call to broadcast error if error code is not on array
	if ([401, 403].indexOf(code) === -1) {
		broadcastError("Api Error", error, {code, ...datas});
	}
}

const broadcastError = (type, error, datas) => {
	const params = { type, error, store: getStore(), datas, browser: browser() };
	console.log(`:-: Broadcast ${type} - ${datas.code} :-:`, params); //eslint-disable-line no-console

	const { id, firstName, lastName } = params.store.env.user;
	appsignal.sendError(error, (span) => {
	  span.setTags({
		id,
		name: `${firstName} ${lastName}`,
		currentUrl: window.location.href,
		type,
		error,
		datas,
	  });
	});
}
