import React from 'react';
import Name from '../../components/ui/Name';
import activities from './activities';
import countries from './countries';
import themes from './themes';
import routes from '../../config/routes';
import global from './global';
import catalog from './catalog';
import { STATUS_QUALIFIED, STATUS_REJECTED, STATUS_BLOCKED, STATUS_UNQUALIFIED, STATUS_CLOSED } from '../../config/profile';

const status = {
	[STATUS_QUALIFIED]: `Validé`,
	[STATUS_UNQUALIFIED]: `A traiter`,
	[STATUS_REJECTED]: `Refusé`,
	[STATUS_BLOCKED]: `Bloqué`,
	[STATUS_CLOSED]: `Supprimé`
};

export default {
	errors: {
		deleted: `Ce profil a été supprimé.`,
	},
	deletedOn: (date) => `Compte supprimé le ${date}`,
	status: {
		...status,
		undeleteConfirm: `Cet influenceur a choisi de supprimer son compte. Êtes-vous sûr de vouloir modifier son statut ?`,
		label: `STATUT`,
		change: (name, label, status) => <div>Changer le statut de <Name {...name} /> vers <span className="profile-state-to"><span className={`state-icon state--${status}`} />{label}</span> ?</div>,
		yes: `Modifier le statut`,
		no: `Annuler`,
		title: `Changer le statut du profil`,
	},
	messages: {
		saved: `Profil enregistré avec succès !`,
		invalid: `Les champs suivants sont incorrects :`,
		merged: `Profils fusionnés et enregistrés avec succès !`,
		deduped: `Profils dédoublonnés avec succès !`,
	},
	tools: {
		profile: `Voir plus d'informations`,
		create: `Générer une célébrité`,
		createError: `Vous devez au préalable renseigner et sauvegarder une activité pour pouvoir générer une célébrité.`,
		delete: `Supprimer le profil`,
		openProfile: `Aperçu de la fiche`,
		merge: `Fusionner le profil`,
		dedupe: `Dédoublonner le profil`,
		switch: `Switch User`,
		convertTo: `Convertir vers...`
	},
	new: {
		title: `Nouveau profil`,
	},
	tabs: {
		default: `Profil public`,
		influence: `Influence`,
	},
	tab: {
		save: `Sauvegarder`,
		activated: engine => `Profil activé sur le dispositif ${engine}`,
		deactivated: engine => `Profil désactivé sur le dispositif ${engine}`,
		pending: engine => `Remplissez les champs pour rendre le profil actif sur le dispositif ${engine}`,
		acceptReview: `Valider le profil`,
		editReview: `Modifier le profil`,
		addedOn: date => `Ajouté le ${date}`,
	},
	members: {
		community: registeredAt => `Membre Community depuis le ${registeredAt}`,
		legacy: `Membre Legacy`,
	},
	subscriptionToStatsReport : {
		subscribedToStatsReport: 'Abonné au rapport de stats',
		unsubscribedToStatsReport: 'Désabonné du rapport de stats'
	},
	fields: {
		enlisted: {
			label: `Mobilisation lié au coronavirus`,
		},
		members: {
			label: `Membres`,
			...global.select,
		},
		fullName: {
			label: `Nom complet`,
			placeholder: "",
		},
		firstName: {
			label: `Prénom`,
			placeholder: "Allison",
			helper: `Le prénom/nom OU le nom de scène doivent être renseignés`
		},
		lastName: {
			label: `Nom de famille`,
			placeholder: "JULIEN",
		},
		name: {
			label: `Nom`,
			placeholder: '',
			helper: '',
		},
		stageName: {
			label: `Nom de scène`,
			placeholder: ``,
		},
		website: {
			label: `Site officiel`,
			placeholder: `http://www.website.com`,
		},
		phone: {
			label: `Numéro de téléphone`,
		},
		email: {
			label: `Email de contact`,
		},
		otherEmails: {
			label: `Adresses email`,
			addMore: global.select.addMore,
		},
		gender: {
			...global.genders,
			label: 'Genre',
		},
		activities: {
			label: `Métier(s)`,
			search: global.search,
			more: global.select.more,
			addMore: global.select.addMore,
			...activities,
		},
		shortDescription: {
			label: `Description courte`,
			helper: `Description en quelques mots (80 caractères max)`
		},
		description: {
			label: `Description`,
			helper: `Description en quelques phrases (3000 caractères max)`
		},
		longDescription: {
			label: `Description longue`,
			helper: `Description libre`
		},
		birthdate: {
			label: `Date de naissance`,
			date: global.date,
		},
		nationalities: {
			label: `Nationalité(s)`,
			search: global.search,
			more: global.select.more,
			addMore: global.select.addMore,
			...countries
		},
		influencingCountries: {
			label: `Pays d'influence (requis si aucun compte sur Instagram ou Facebook)`,
			search: global.search,
			more: global.select.more,
			addMore: global.select.addMore,
			...countries
		},
		influencingCountriesAuto: {
			label: `Pays d'influence calculés automatiquement`,
			search: global.search,
			more: global.select.more,
			addMore: global.select.addMore,
			...countries
		},
		themesInfluence: {
			label: `Thème(s) d'influence`,
			search: global.search,
			more: global.select.more,
			addMore: global.select.addMore,
			...themes,
		},
		keyFacts: {
			label: `Infos-clés`,
			addMore: global.select.addMore,
		},
		socialNetworksSuggestions: {
			label: `Réseaux sociaux suggérés`,
		},
		socialNetworks: {
			label: `Réseaux sociaux`,
			add: `+ Ajouter un compte social`,
			addSNA: {
				title: `Ajouter un compte social`,
				label: `URL du profil`,
				helper: `Collez l'url du profil d'un compte social puis cliquez sur "Vérifier"`,
				verify: `Vérifier`,
				merge: `Fusionner le profil`,
				confirm: `Ajouter le compte social`,
				cancel: `Annuler`,
				errors: {
					invalid: `Désolé, cette url ne semble être valide sur aucun réseau (facebook, instagram, youtube, twitter, tiktok)`,
					'invalid-youtube': `Vous devez entrer l'url youtube d'une chaine`,
					dne: `Désolé, ce compte social ne semble pas exister`,
					linked: props => (<span>Ce compte social est déjà lié au profil de <a href={`${routes.catalog[props.type || 'profile']}${props.id}`} rel="noopener noreferrer" target="_blank"><span className="modale-profile-name"><Name {...props} /></span></a></span>),
					private: `Désolé, ce compte social est privé...`,
				},
				community: `Communauté :`,
				accountIsPrivate: `Ce compte existe mais est privé. Impossible de récupérer ses informations`,
				search: catalog.search
			},
			spammer: {
				title: `Changer le statut du compte spammer`,
				collected: (
					<p>
					  Compte spammer bypassé par un administrateur. La collecte est activée.
					  <br />
					  Cliquez pour interrompre la collecte de ce compte
					</p>
				),
				notCollected: (
					<p>
					  Compte identifié comme spammer. La collecte est désactivée.
					  <br />
					  Cliquez pour relancer la collecte de ce compte
					</p>
				),
				collect: <p>Êtes-vous sûr(e) de vouloir <strong>déclencher</strong> la collecte des données pour ce compte ?</p>,
				interrupt: <p>Êtes-vous sûr(e) de vouloir <strong>interrompre</strong> la collecte des données pour ce compte ?</p>,
				yes: `Oui`,
				no: `Non`,
				collectSuccess: `Collecte des données déclenchée avec succès !`,
				interruptSuccess: `Collecte des données interrompue avec succès !`,
			}
		},
		profilesSuggestions: {
			label: `Profiles suggérés`,
			profile: `Profil`,
			link: `Lier`,
			successMessage: `Profils fusionnés et enregistrés avec succès !`,
		},
		addresses: {
			search: global.search,
			more: global.select.more,
			living: {
				gmap: `Lieu de résidence`,
				label: `Lieu de résidence`,
			},
			socialData: {
				gmap: `Social Data`,
				label: `Social Data`,
			},
		},
		status: {
			label: `STATUT`,
			[STATUS_QUALIFIED]: status[STATUS_QUALIFIED],
			[STATUS_REJECTED]: status[STATUS_REJECTED],
		}
	},
	modales: {
		changeTab: {
			title: `Attention !`,
			body: `Vous avez des données non enregistrées, si vous changez d'onglet, vos modifications seront perdues.\n\nÊtes-vous sûr de vouloir changer d'onglet ?`,
			yes: `Oui, changer d'onglet`,
			no: 'Annuler'
		},
		confirmChangePage: {
			title: `Attention !`,
			body: `Vous avez des données non enregistrées, si vous changez de page, vos modifications seront perdues.\n\nÊtes-vous sûr de vouloir changer de page ?`,
			yes: `Oui, changer de page`,
			no: 'Annuler'
		},
		deleteProfile: {
			title: `Supprimer un profil`,
			body: props => (<div>Êtes-vous sûr de vouloir supprimer <span className="modale-profile-name"><Name {...props} /></span> ?<br />Attention cette action est irréversible et ne pourra être annulée.</div>),
			yes: `Supprimer`,
			no: `Annuler`
		},
		addProfile: {
			title: `Ajouter un profil`,
			cancel: `Annuler`,
			confirm: `Créer le profil`,
			matchingProfiles: `Les profils suivants peuvent correspondre au profil que vous souhaitez créer. Si c'est le cas, merci d'éditer la fiche du profil correspondant et ne pas créer de nouveau profil`,
			cgv: `Je certifie que le profil que je veux créer n'existe pas déjà.`,
			success: `Profil créé avec succès ! Vous pouvez maintenant éditer sa fiche !`,
			search: {
				noResult: ``,
			}
		},
		merge: {
			title: `Fusionner avec...`,
			cancel: `Annuler`,
			matchingProfiles: `Sélectionnez le profil avec lequel vous souhaitez fusionner.`,
			merge: `Fusionner`,
			dedupe: `Dédoublonner`,
			search: {
				noResult: ``,
			}
		},
		confirmLightMerge: {
			title: `Lier un compte social à un profil`,
			body: profile => (<div>Êtes-vous sûr(e) de vouloir lier ce compte social à <span className="modale-profile-name"><Name {...profile} /></span> ?</div>),
			yes: `Oui, lier`,
			no: `Annuler`
		},
		confirmMerge: {
			title: `Sélectionner les champs à conserver`,
			confirm: `Fusionner`,
			cancel: `Annuler`,
			emptyProfile: `Champs libres`
		},
		mergeError: {
			title: 'Fusion impossible',
			body: `Désolé, ces 2 profils correspondent à des célébrités distinctes, ou des membres community, et ne peuvent donc pas être fusionnés.`,
			ok: `Ok`,
		},
		switchUserError: {
			title: 'Switch User impossible',
			body: `Désolé, ce profil ne semble pas être validé. Le Switch user est donc impossible pour ce profil.`,
			ok: `Ok`,
		},
		confirmConvertProfileTo: {
			title: profile => <span>Convertir <Name {...profile} /> vers...</span>,
			warning: `Attention, vous allez perdre tous les champs qui ne sont pas en commun avec cette entité. Cette action est irréversible et ne pourra pas être annulée.`,
			no: `Annuler`,
			yes: `Convertir`,
			profile: `Un profil`,
			collective: `Un collectif`,
			brand: `Une marque`,
			media: `Un média`,
			other: `Autre`,
		}
	}
};
