const defaultErrorMessage = `Une erreur critique est survenue... Veuillez réessayer dans quelques minutes et nous excuser pour la gêne occasionnée.`

export default {
	engines: {
		influence: `Influence`,
		ambassador: `Ambassadeur`,
		event: `Event`,
	},
	networks: {
		facebook: `Facebook`,
		twitter: `Twitter`,
		youtube: `Youtube`,
		instagram: `Instagram`,
		tiktok: 'TikTok',
	},
	locales: {
		fr:  `Français`,
		en: `Anglais`,
	},
	genders: {
		male: `Homme`,
		female: `Femme`,
		other: `Autre`,
	},
	date: {
		year: `Année`,
		month: `Mois`,
		day: `Jour`,
		months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
	},
	search: `Rechercher...`,
	select: {
		more: v => `+${v} éléments...`,
		add: '+ Ajouter',
		addMore: (v, max) => `+ Ajouter (${v}/${max})`,
	},
	errors: {
		title: code => `Oups... Erreur ${code} !`,
		ok: `Ok`,
		codes: {
			400: defaultErrorMessage,
			401: `Vous n'êtes pas identifé, ou votre session a expiré. Vous allez être redirigé vers l'écran de connexion...`,
			403: `Vous n'êtes pas autorisé à effectuer cette action.`,
			404: `La ressource que vous demandez ne semble pas exister.`,
			422: defaultErrorMessage,
			500: defaultErrorMessage,
			502: defaultErrorMessage,
			504: "Serveur en charge - Gateway Timeout",
			FETCH: defaultErrorMessage,
			PARSE: defaultErrorMessage,
			RESPONSE: defaultErrorMessage,
		},
		offline: {
			title: `Vous n'êtes pas connecté à Internet, ou le serveur ne répond pas !`,
			body: `Du coup forcément, ca va moins bien marcher maintenant... En attendant que ca revienne, un peu de culture inutile ! Et ne vous inquietez pas, ce message disparaitra quand tout rentrera dans l'ordre !`,
			dyk: [
				`Le système anti-blocage des roues « ABS » a été inventé en 1920 !`,
				`La citrouille d’Halloween était à l’origine un navet !`,
				`Si vous passez une journée sur chacune des îles des Philippines, il vous faudrait près de 21 ans pour toutes les visiter !`,
				`Un cigare à moitié fumé par Winston Churchill a été vendu à 12 000 dollars !`,
				`Aucun livre ne peut quitter ou entrer dans le Tadjikistan sans autorisation écrite du Ministère de la Culture !`,
				`Il neige du métal sur la planète Vénus !`,
				`Les flamants sont roses parce qu’ils mangent des crevettes !`,
				`La bière de table (1,5% d’alcool) a été servie dans les écoles belges jusqu’aux années 1980 !`,
				`Depuis 1948, 100 avions ont disparu en plein vol et n’ont jamais été retrouvés !`,
				`Les harengs pètent pour communiquer !`,
				`La durée de gestation d’un éléphant est de 22 mois !`,
				`Le mot anglais « spread » possède plus de 20 significations en français`,
				`La banane est radioactive !`,
				`Les chimpanzés peuvent jouer à pierre-feuille-ciseaux !`,
				`Les vidéos de Facebook enregistrent plus de 8 milliards de vues quotidiennement !`,
				`La route la plus longue du monde s’étend de l’Alaska au point le plus extrême de l’Amérique du Sud, soit 48 000 km !`,
				`Chaque minute, 400 heures de vidéos sont mises en ligne sur YouTube !`,
				`12% de la population rêve en noir et blanc !`,
				`YouTube était censé être un site de rencontres en ligne !`,
				`En 1916, à Montana, USA, la température a baissé de 55,7°C en une seule journée !`,
				`En chine, il y a des distributeurs automatiques de crabes vivants !`,
				`L’animal aux plus gros testicules est une espèce de sauterelle !`,
				`L’arbre le plus haut du monde mesure 115,55 mètres !`,
				`En 1814, à Londres, une inondation de bière a tué huit personnes !`,
				`Au sommet du mont Everest, l’eau se met à bouillir à 72°C !`,
				`Durant la prohibition, le gouvernement des États-Unis a empoisonné l’alcool tuant plus de 10 000 personnes !`,
				`Au japon, le nombre quatre est considéré comme porte-malheur !`,
				`Le plus grand volcan du système solaire est le Mont Olympus, sur Mars, haut de 21 229 mètres !`,
				`Cendrillon est connue sous le nom de Zezolla en Italie, Yeh -hsien en Chine et Rashin Coatie en Ecosse !`,
				`Le Canada a plus de lacs que le reste des pays du monde combinés !`,
				`Un homme a sauvé la vie de plus de deux millions de bébés grâce à son sang !`,
				`Une médaille d’or olympique est composée de 98,9% d’argent !`,
				`En Chine, un hôtel de 15 étages a été construit en seulement 6 jours !`,
				`Un homme a survécu aux deux bombardements atomiques de Hiroshima et Nagasaki !`,
				`Il existe des oiseaux venimeux !`,
				`En 1998, la foudre a tué toute une équipe de football !`,
				`Les coccinelles sont carnivores !`,
				`La diphallia est une malformation qui cause une duplication du pénis !`,
				`La gare la plus fréquentée du monde accueille près de 1,3 milliard de passagers chaque année !`,
				`Un facteur espagnol a écopé d'une peine de prison de 384 912 ans !`,
				`La vitesse du vent le plus fort jamais enregistré est de 512 km/h !`,
				`Un aveugle a retrouvé la vue grâce à l’implantation d’une dent dans son œil droit !`,
				`Mickey Mouse a été interdit en Roumanie en 1935 parce que les autorités pensaient qu’il faisait peur aux enfants !`,
				`La plus grande grenouille de la planète est de la taille d’un lapin !`,
				`Les Mayas perçaient leurs pénis pour offrir leur sang à leurs dieux !`,
				`Selon une étude, deux pintes de bière seraient plus efficaces que le paracétamol pour soulager la douleur !`,
				`Le lait de chamelle contient 10 fois plus de fer que le lait de vache !`,
				`« Chargoggagogg­manchauggagogg­chaubunagungamaugg » est le nom d’un lac aux Etats-Unis !`,
				`Le dessin de Kate Winslet nue réalisé par Dicaprio dans Titanic, a été réellement réalisé par James Cameron !`,
				`Un étudiant chinois a pu mémoriser 67 890 décimales de Pi !`,
				`En inde, il y a une boisson gazeuse fabriquée à partir d’urine de vache !`,
				`Les baleines bleues peuvent manger un demi-million de calories en une seule bouchée !`,
				`En 2005, une brésilienne a poursuivi son partenaire en justice pour avoir échoué à la faire jouir !`,
				`La plus longue partie de Monopoly jamais jouée a duré 70 jours consécutifs !`,
				`Les conserves ont été inventées grâce à Napoléon Bonaparte !`,
			],
		}
	}
}
