export default {
	title: 'Logs',
	listing: {
		header: {
			admin: `Admin`,
			date: `Date`,
			action: `Type`,
			details: `Details`,
			actions: `Actions`
		},
		actions: {
			'public_profile_to_brand': "Conversion vers une Marque",
			'public_profile_to_collective': "Conversion vers un Collectif",
			'public_profile_to_other': "Conversion vers un Autre",
			'public_profile_merged': "Liaison à un profil existant",
			'public_profile_updated_and_qualified': "Sauvegarde du profil"
		},
		changes: `changement(s)`,
		seeProfile: `Voir le profil`,
		reviewProfile: `Contrôler`,
		from: `De...`,
		to: `Vers...`,
	},
	filters: {
		admin: `Nom de l'Admin`,
		toReview: `Actions à vérifier`,
		startAt: `Du...`,
		endAt: `Au...`,
		date: `Dates`,
		adminPlaceholder: `Choisissez un admin`
	},
	stats: {
		admin: `Admin`,
		publicProfile: `Personnes`,
		collective: `Collectifs`,
		brand: `Marques`,
		media: `Médias`,
		other: `Autres`,
		merged: `Liaisons`,
		total: `Total`,
		show: `Statistiques`,
	},

}
