import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as LOGS from '../constants/logs';

const initialState = {
	datas: [],
	total: 0,
	loading: true,
	filters: {
		page: 0,
		toReview: false,
		adminId: null,
		startAt: null,
		endAt: null,
	},
	reviewing: {
		profileId : null,
		logId: null,
	},
	stats: [],
};

const reducers = {
	[LOGS.SEARCH_REQUEST]: state => update(state, {
		loading: { $set: true }
	} ),
	[LOGS.SEARCH_COMPLETE]: (state, {payload: {results, total}}) => update(state, {
		loading: { $set: false },
		datas: { $set: results },
		total: { $set: total },
	} ),
	[LOGS.FILTER_UPDATE]: (state, {payload: {filter, value}}) => update(state, {
		filters: {
			[filter]: { $set: value }
		}
	} ),
	[LOGS.REVIEW_ID]: (state, {payload: reviewing}) => update(state, {
		reviewing: { $set: reviewing }
	} ),
	[LOGS.REVIEWED_ID]: (state, {payload: logId}) => update(state, {
		datas: { $set: state.datas.map(log => log.id === logId ? ({
			...log,
			toReview: false,
		}) : log) }
	} ),
	[LOGS.STATS_COMPLETE]: (state, {payload: stats}) => update(state, {
		stats: { $set: stats },
	} ),
}


export default createReducer(initialState, reducers);
