import { combineReducers } from 'redux';

import env from './env';
import catalog from './catalog';
import profile from './profile';
import discovery from './discovery';
import insideSales from './insideSales';
import logs from './logs';
import accounts from './accounts';
import features from './features';

const reducers = combineReducers( {
	env,
	catalog,
	profile,
	discovery,
	insideSales,
	logs,
	accounts,
	features,
});

export type AppState = ReturnType<typeof reducers>;

export default reducers;
