import React, { useCallback, useMemo, useState } from "react";
import { TextField, Button, Link } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Link as RouterLink } from "react-router-dom";

import { requestPasswordReset } from "actions/auth";
import { useDispatch } from "utils/redux";
import { useI18n } from "locales";
import routes from "config/routes";

import ErrorMessage from "../ErrorMessage";
import styles from "./RequestPasswordReset.module.scss";

const RequestPasswordReset = () => {
  const dispatch = useDispatch();
  const {
    auth: { requestPasswordReset: lexique },
  } = useI18n();

  const [state, setState] = useState({
    email: "",
    loading: false,
    resetRequestSent: false,
    errorCode: "",
  });

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setState((prevState) => ({
      ...prevState,
      resetRequestSent: false,
      email: value,
      errorCode: "",
    }));
  }, []);

  const handlePasswordReset = useCallback(
    async (e) => {
      e.preventDefault();
      setState((prevState) => ({
        ...prevState,
        loading: true,
        resetRequestSent: false,
        errorCode: "",
      }));
      const { error } = await dispatch(
        requestPasswordReset({
          email: state.email,
        })
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
        errorCode: error || "",
        resetRequestSent: !error,
      }));
    },
    [dispatch, state.email]
  );

  const submitButtonContent = useMemo(() => {
    if (state.resetRequestSent) return lexique.submitted;
    if (state.loading) return lexique.loading;
    return lexique.requestPasswordReset;
  }, [state.resetRequestSent, state.loading, lexique]);

  return (
    <form className={styles.container} onSubmit={handlePasswordReset}>
      <h1 className={styles.title}>{lexique.askIfPasswordForgotten}</h1>
      <p>{lexique.instruction}</p>
      <div className={styles.fields}>
        <TextField
          id="auth-login-email-label"
          label={lexique.email}
          variant="outlined"
          type="email"
          value={state.email}
          onChange={handleEmailChange}
          disabled={state.loading}
          error={!!state.errorCode}
        />
        {state.errorCode && (
          <ErrorMessage
            context="requestPasswordReset"
            errorCode={state.errorCode}
          />
        )}
      </div>
      <Button
        size="large"
        type="submit"
        variant="contained"
        className="btn-raised btn-raised-green"
        disabled={state.loading || !state.email}
        icon={state.resetRequestSent ? "check" : ""}
        startIcon={state.resetRequestSent ? <CheckIcon /> : undefined}
      >
        {submitButtonContent}
      </Button>
      <div className={styles.askIfWantsToLogin}>
        <Link to={routes.auth.login} color="inherit" component={RouterLink}>
          {lexique.login}
        </Link>
      </div>
    </form>
  );
};

export default RequestPasswordReset;
