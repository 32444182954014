import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useI18n } from "locales";
import styles from "./ErrorMessage.module.scss";

const unsupportedErrorCodeCheck = /KeyNotFound/g;

const ErrorMessage = ({ errorCode, context }) => {
  const {
    auth: {
      [context]: { errors: lexique },
    },
  } = useI18n();

  const errorMessage = useMemo(() => {
    if (!errorCode) return "";
    const eMessage = lexique[errorCode];
    if (unsupportedErrorCodeCheck.test(eMessage)) return lexique.unknown;
    return eMessage;
  }, [errorCode, lexique]);

  return (
    <div className={styles.errorMessage}>
      <p>{errorMessage}</p>
    </div>
  );
};

ErrorMessage.propTypes = {
  errorCode: PropTypes.string.isRequired,
  context: PropTypes.oneOf(["login", "requestPasswordReset"]).isRequired,
};

export default ErrorMessage;
