import header from './fr-FR/header';
import catalog from './fr-FR/catalog';
import global from './fr-FR/global';
import profile from './fr-FR/profile';
import brand from './fr-FR/brand';
import media from './fr-FR/media';
import collective from './fr-FR/collective';
import other from './fr-FR/other';
import discovery from './fr-FR/discovery';
import insideSales from './fr-FR/insideSales';
import logs from './fr-FR/logs';
import accounts from './fr-FR/accounts';
import countries from './fr-FR/countries';
import featuresAndPackages from './fr-FR/featuresAndPackages';
import auth from './fr-FR/auth';

export default {
	config: {
		label: 'Français',
		iso3: "FRA",
		iso: 'fr-FR',
		dateFormat: 'DD/MM/YYYY',
	},
	header,
	catalog,
	global,
	profile,
	brand,
	media,
	collective,
	other,
	discovery,
	insideSales,
	logs,
	accounts,
	countries,
	featuresAndPackages,
	auth,
}
