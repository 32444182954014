import { env, ENV_DEV, DEV_DEBUG } from 'config/env';
import { randomBool } from 'utils/random';
import { doAfterTimeout } from 'utils/timeout';

import { get } from './index';

const DEBUG = env === ENV_DEV && DEV_DEBUG;

export const getFeatures = () => {
  const endpoint = `catalog/features`;
  if (!DEBUG) return get(endpoint);
  console.info('api.features.getFeatures - ', endpoint); //eslint-disable-line no-console

  return doAfterTimeout(
    [
      {
        name: 'influence/unicore',
        description: 'blabla',
        application: 'influence',
        destroyable: randomBool(),
      },
      {
        name: 'influence/favorites',
        description: 'blabla',
        application: 'influence',
        destroyable: randomBool(),
      },
      {
        name: 'influence/campaign_manage',
        description: 'blabla',
        application: 'influence',
        destroyable: randomBool(),
      },
      {
        name: 'influence/labels',
        description: 'blabla',
        application: 'influence',
        destroyable: randomBool(),
      },
      {
        name: 'influence/likers_insights',
        description: 'blabla',
        application: 'influence',
        destroyable: randomBool(),
      },
      {
        name: 'influence/followers_insights',
        description: 'blabla',
        application: 'influence',
        destroyable: randomBool(),
      },
      {
        name: 'extension/unicore',
        description: 'blabla',
        application: 'influence',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/qualifications',
        description: 'blabla',
        application: 'catalog',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/base',
        description: 'blabla',
        application: 'catalog',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/logs',
        description: 'blabla',
        application: 'catalog',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/update_status',
        description: 'blabla',
        application: 'catalog',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/users_and_teams',
        description: 'blabla',
        application: 'catalog',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/features',
        description: 'blabla',
        application: 'catalog',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/packages',
        description: 'blabla',
        application: 'catalog',
        destroyable: randomBool(),
      },
      {
        name: 'catalog/banner_announcement',
        description: 'Banner announcements',
        application: 'catalog',
        destroyable: randomBool(),
      },
    ],
    500,
  );
};

export const getPackages = () => {
  const endpoint = `catalog/feature_groups`;
  if (!DEBUG) return get(endpoint);
  console.info('api.features.getPackages - ', endpoint); //eslint-disable-line no-console

  return doAfterTimeout(
    [
      {
        id: 0,
        name: 'admin/operator',
        description: 'Discovery',
        application: 'catalog',
        active: true,
        features: ['catalog/base'],
      },
      {
        id: 1,
        name: 'admin/master_peon',
        description: 'Discovery & Logs',
        application: 'catalog',
        active: true,
        features: ['catalog/base', 'catalog/logs'],
      },
      {
        id: 2,
        name: 'admin/income_calls',
        description: 'Income Calls',
        application: 'catalog',
        active: true,
        features: ['catalog/qualifications', 'catalog/base'],
      },
      {
        id: 3,
        name: 'admin/catalog',
        description: 'Catalog Manager',
        application: 'catalog',
        active: true,
        features: [
          'catalog/qualifications',
          'catalog/logs',
          'catalog/update_status',
        ],
      },
      {
        id: 4,
        name: 'admin/admin',
        description: 'Admin',
        application: 'catalog',
        active: true,
        features: [
          'catalog/qualifications',
          'catalog/logs',
          'catalog/update_status',
          'catalog/users_and_teams',
        ],
      },
      {
        id: 28,
        name: 'package/free',
        description: 'Extension',
        application: 'influence',
        active: true,
        features: ['extension/unicore', 'influence/followers_insights'],
        memberships_count: 26,
      },
      {
        id: 5,
        name: 'package/standard',
        description: 'Standard Plan',
        application: 'influence',
        active: true,
        features: ['extension/unicore', 'influence/followers_insights'],
      },
      {
        id: 6,
        name: 'package/paid',
        description: 'Paid Plan',
        application: 'influence',
        active: true,
        features: ['influence/unicore'],
      },
      {
        id: 99,
        name: 'admin/semideus',
        description: 'Not totally a God, but close',
        application: 'catalog',
        active: true,
        features: [],
      },
    ],
    500,
  );
};

export const getPackageUsers = ({ packageId, page, perPage }) => {
  const endpoint = `catalog/feature_groups/users`;
  if (!DEBUG)
    return get(endpoint, {
      name: packageId,
      per_page: perPage || 10,
      page: page || 0,
    });

  console.info('api.features.getPackagesUsers - ', endpoint); //eslint-disable-line no-console
  return doAfterTimeout(true, 500);
};

export const getFeatureUsers = ({ featureId, page, perPage }) => {
  const endpoint = `catalog/features/users`;
  if (!DEBUG)
    return get(endpoint, {
      name: featureId,
      per_page: perPage || 10,
      page: page || 0,
    });

  console.info('api.features.getFeatureUsers - ', endpoint); //eslint-disable-line no-console
  return doAfterTimeout(true, 500);
};
