import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import moment from 'moment';

import * as INSIDE_SALES from '../constants/insideSales';

const initialState = {
  search: {
    companyName: '',
    adminId: '',
    status: '',
    startDate: null,
    endDate: null,
  },
  qualifications: [],
  accountExecutives: [],
  currentPage: 0,
  total: 10,
};

const reducers = {
  [INSIDE_SALES.CREATE_REQUEST]: state => update(state, {
    search: {
      loading: { $set: true }
    }
  } ),
  [INSIDE_SALES.CREATION_DONE]: state => update(state, {
    search: {
      loading: { $set: false }
    }
  } ),
  [INSIDE_SALES.SEARCH_UPDATE]: (state, action) => update(state, {
    search: {
      companyName:  { $set: action.payload },
    }
  } ),
  [INSIDE_SALES.SEARCH_ONCHANGE]: (state, {payload:{ status, adminUserId, startDate, endDate, currentPage }}) => update(state, {
		search: {
      status:       { $set: status },
      adminId:      { $set: adminUserId },
      startDate:    { $set: startDate ? moment(startDate) : null },
      endDate:      { $set: endDate ? moment(endDate) : null },
		},
    currentPage:  { $set: currentPage },
	} ),
	[INSIDE_SALES.SEARCH_COMPLETE]: (state, payload) => update(state, {
    qualifications: { $set: payload.payload.qualifications },
    total:          { $set: payload.payload.total },
	} ),
	[INSIDE_SALES.SEARCH_RESET]: state => update(state, {
      search: { $set: initialState.search }
	} ),
}


export default createReducer(initialState, reducers);
