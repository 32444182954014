import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'react-dates/initialize';
//import { conf } from './config/env';

import store, { history } from './config/store';
//import registerServiceWorker from './registerServiceWorker';
//import configureGATracker from './utils/ga';
import MainContainer from './components/MainContainer';

import 'react-toastify/dist/ReactToastify.css';
import 'react-tippy/dist/tippy.css';
import 'react-dates/lib/css/_datepicker.css';

import './css/scss/index.scss';
import './css/scss/form.scss';
import './css/scss/pagination.scss';
import './css/scss/input-gmap.scss';
import './css/scss/catalog.scss';
import './css/scss/discovery.scss';
import './css/scss/profile.scss';
import './css/scss/inside-sales.scss';
import './css/scss/profile-layouts.scss';
import './css/scss/inside-sales.scss';
import './css/scss/logs.scss';
import './css/scss/accounts.scss';
import './css/scss/giftbox.scss';

//configureGATracker(conf.GATracker);

const dom = document.getElementById('root');

ReactDOM.render(
    <Provider store={ store }>
		<Router history={history}>
			<MainContainer />
		</Router>
    </Provider>, dom);

// Hot reloading
if (module && module.hot) {
    module.hot.accept();
}
