import React, { PureComponent } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

class MenuIconButton extends PureComponent {
	state = { opened: false, anchor: null }

	openMenu = e => {
		this.setState({opened: true, anchor: e.currentTarget });
	}

	closeMenu = () => {
		this.setState({opened: false, anchor: null });
	}

	handleItemClick = child => () => {
		child.props.onClick();
		this.closeMenu();
	}

	render() {
		const { opened, anchor } = this.state;
		const { id, button, children } = this.props;
		return (
		<div className="menu-icon-button">
			<IconButton
				aria-label="More"
				aria-owns={opened ? id : null}
				aria-haspopup="true"
				onClick={this.openMenu}
			>
				{ button }
			</IconButton>
			<Menu
				id={id}
				anchorEl={anchor}
				open={opened}
				onClose={this.closeMenu}
			>
				{
					React.Children.map(children, child => {
						if (child === null) return null;
						return React.cloneElement(child, {
							key: child,
							onClick: this.handleItemClick(child)
						});
					})
				}
			</Menu>
		</div>
		);
	}
}

export default MenuIconButton;
